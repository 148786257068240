export const GET_FORM_REQUEST = "GET_FORM_REQUEST";
export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_FAILURE = "GET_FORM_FAILURE";

export const ADD_FORM_REQUEST = "ADD_FORM_REQUEST";
export const ADD_FORM_SUCCESS = "ADD_FORM_SUCCESS";
export const ADD_FORM_FAILURE = "ADD_FORM_FAILURE";

export const GET_FORM_BYID_REQUEST = "GET_FORM_BYID_REQUEST";
export const GET_FORM_BYID_SUCCESS = "GET_FORM_BYID_SUCCESS";
export const GET_FORM_BYID_FAILURE = "GET_FORM_BYID_FAILURE";

export const EDIT_FORM_BYID_REQUEST = "EDIT_FORM_BYID_REQUEST";
export const EDIT_FORM_BYID_SUCCESS = "EDIT_FORM_BYID_SUCCESS";
export const EDIT_FORM_BYID_FAILURE = "EDIT_FORM_BYID_FAILURE";

export const DELETE_FORM_BYID_REQUEST = "DELETE_FORM_BYID_REQUEST";
export const DELETE_FORM_BYID_SUCCESS = "DELETE_FORM_BYID_SUCCESS";
export const DELETE_FORM_BYID_FAILURE = "DELETE_FORM_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_FORM_BY_ID_REQUEST = "GET_FORM_BY_ID_REQUEST";
export const GET_FORM_BY_ID_SUCCESS = "GET_FORM_BY_ID_SUCCESS";
export const GET_FORM_BY_ID_FAILURE = "GET_FORM_BY_ID_FAILURE";

export const EXPORT_FORM_TABLE_REQUEST = "EXPORT_FORM_TABLE_REQUEST";
export const EXPORT_FORM_TABLE_SUCCESS = "EXPORT_FORM_TABLE_SUCCESS";
export const EXPORT_FORM_TABLE_FAILURE = "EXPORT_FORM_TABLE_FAILURE";

export const GET_ALL_FORM_LOG_REQUEST = "GET_ALL_FORM_LOG_REQUEST";
export const GET_ALL_FORM_LOG_SUCCESS = "GET_ALL_FORM_LOG_SUCCESS";
export const GET_ALL_FORM_LOG_FAILURE = "GET_ALL_FORM_LOG_FAILURE";

export const RESTORE_FORM_LOG_REQUEST = "RESTORE_FORM_LOG_REQUEST";
export const RESTORE_FORM_LOG_SUCCESS = "RESTORE_FORM_LOG_SUCCESS";
export const RESTORE_FORM_LOG_FAILURE = "RESTORE_FORM_LOG_FAILURE";

export const DELETE_FORM_LOG_REQUEST = "DELETE_FORM_LOG_REQUEST";
export const DELETE_FORM_LOG_SUCCESS = "DELETE_FORM_LOG_SUCCESS";
export const DELETE_FORM_LOG_FAILURE = "DELETE_FORM_LOG_FAILURE";

export const UPDATE_FORM_ENTITY_PARAMS_SUCCESS = "UPDATE_FORM_ENTITY_PARAMS_SUCCESS";
export const UPDATE_FORM_ENTITY_PARAMS_FAILURE = "UPDATE_FORM_ENTITY_PARAMS_FAILURE";

export const UPLOAD_RECORD_IN_FORM_REQUEST = "UPLOAD_RECORD_IN_FORM_REQUEST";
export const UPLOAD_RECORD_IN_FORM_SUCCESS = "UPLOAD_RECORD_IN_FORM_SUCCESS";
export const UPLOAD_RECORD_IN_FORM_FAILURE = "UPLOAD_RECORD_IN_FORM_FAILURE";

export const GET_FORM_RECORD_REQUEST = "GET_FORM_RECORD_REQUEST";
export const GET_FORM_RECORD_SUCCESS = "GET_FORM_RECORD_SUCCESS";
export const GET_FORM_RECORD_FAILURE = "GET_FORM_RECORD_FAILURE";

export const REMOVE_ATTACHMENT_REQUEST = "REMOVE_ATTACHMENT_REQUEST";
export const REMOVE_ATTACHMENT_SUCCESS = "REMOVE_ATTACHMENT_SUCCESS";
export const REMOVE_ATTACHMENT_FAILURE = "REMOVE_ATTACHMENT_FAILURE";
