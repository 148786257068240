export const GET_SMART_REPORT_REQUEST = "GET_SMART_REPORT_REQUEST"
export const GET_SMART_REPORT_SUCCESS = "GET_SMART_REPORT_SUCCESS"
export const GET_SMART_REPORT_FAILURE = "GET_SMART_REPORT_FAILURE"

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const EXPORT_SMART_REPORT_TABLE_REQUEST = "EXPORT_SMART_REPORT_TABLE_REQUEST"
export const EXPORT_SMART_REPORT_TABLE_SUCCESS = "EXPORT_SMART_REPORT_TABLE_SUCCESS"
export const EXPORT_SMART_REPORT_TABLE_FAILURE = "EXPORT_SMART_REPORT_TABLE_FAILURE"

export const UPDATE_SMART_REPORT_ENTITY_PARAMS_SUCCESS ="UPDATE_SMART_REPORT_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SMART_REPORT_ENTITY_PARAMS_FAILURE ="UPDATE_SMART_REPORT_ENTITY_PARAMS_FAILURE";

