export const GET_CAMPUSES_REQUEST = "GET_CAMPUSES_REQUEST";
export const GET_CAMPUSES_SUCCESS = "GET_CAMPUSES_SUCCESS";
export const GET_CAMPUSES_FAILURE = "GET_CAMPUSES_FAILURE";

export const ADD_CAMPUSES_REQUEST = "ADD_CAMPUSES_REQUEST";
export const ADD_CAMPUSES_SUCCESS = "ADD_CAMPUSES_SUCCESS";
export const ADD_CAMPUSES_FAILURE = "ADD_CAMPUSES_FAILURE";

export const EDIT_CAMPUSES_BYID_REQUEST = "EDIT_CAMPUSES_BYID_REQUEST";
export const EDIT_CAMPUSES_BYID_SUCCESS = "EDIT_CAMPUSES_BYID_SUCCESS";
export const EDIT_CAMPUSES_BYID_FAILURE = "EDIT_CAMPUSES_BYID_FAILURE";

export const DELETE_CAMPUSES_BYID_REQUEST = "DELETE_CAMPUSES_BYID_REQUEST";
export const DELETE_CAMPUSES_BYID_SUCCESS = "DELETE_CAMPUSES_BYID_SUCCESS";
export const DELETE_CAMPUSES_BYID_FAILURE = "DELETE_CAMPUSES_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_CAMPUS_BY_ID_REQUEST = "GET_CAMPUS_BY_ID_REQUEST"
export const GET_CAMPUS_BY_ID_SUCCESS = "GET_CAMPUS_BY_ID_SUCCESS"
export const GET_CAMPUS_BY_ID_FAILURE = "GET_CAMPUS_BY_ID_FAILURE"

export const EXPORT_CAMPUSES_TABLE_REQUEST = "EXPORT_CAMPUSES_TABLE_REQUEST"
export const EXPORT_CAMPUSES_TABLE_SUCCESS = "EXPORT_CAMPUSES_TABLE_SUCCESS"
export const EXPORT_CAMPUSES_TABLE_FAILURE = "EXPORT_CAMPUSES_TABLE_FAILURE"

export const GET_ALL_CAMPUS_LOG_REQUEST = "GET_ALL_CAMPUS_LOG_REQUEST";
export const GET_ALL_CAMPUS_LOG_SUCCESS = "GET_ALL_CAMPUS_LOG_SUCCESS";
export const GET_ALL_CAMPUS_LOG_FAILURE = "GET_ALL_CAMPUS_LOG_FAILURE";

export const RESTORE_CAMPUS_LOG_REQUEST = "RESTORE_CAMPUS_LOG_REQUEST";
export const RESTORE_CAMPUS_LOG_SUCCESS = "RESTORE_CAMPUS_LOG_SUCCESS";
export const RESTORE_CAMPUS_LOG_FAILURE = "RESTORE_CAMPUS_LOG_FAILURE";

export const DELETE_CAMPUS_LOG_REQUEST = "DELETE_CAMPUS_LOG_REQUEST";
export const DELETE_CAMPUS_LOG_SUCCESS = "DELETE_CAMPUS_LOG_SUCCESS";
export const DELETE_CAMPUS_LOG_FAILURE = "DELETE_CAMPUS_LOG_FAILURE";

export const UPDATE_CAMPUS_ENTITY_PARAMS_SUCCESS ="UPDATE_CAMPUS_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CAMPUS_ENTITY_PARAMS_FAILURE ="UPDATE_CAMPUS_ENTITY_PARAMS_FAILURE";
