import React, { Component } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import Highlighter from "react-highlight-words";
import _ from "lodash";

import WildCardFilter from "./WildCardFilter";
import Loader from "./Loader";

const rulesForToolTip = {
    "warning-green.svg":
        "All of the above except : Passed plus failed devices equal total devices and failed devices is greater than 0. ISLM Box is checked. Correction date is filled and correction document is loaded.",
    "warning-red.svg": "Failed devices is greater than 0, and missing correction date or correction documents",
    "plus-icn-red.svg": "Add Overdue Survey",
    "plus-icn.svg": "Add Survey",
    "hashtag-green.svg":
        "All the same as first except: Passed plus failed devices do not equal total devices, however comments have entered. If failed devices is > 0 then all of the above is required",
    "hashtag-red.svg":
        "Passed plus failed devices do not equal total devices, or failed devices do not equal corrected devices, and no comments entered.",
    "attach-red.svg": "Document is not loaded",
    "calendar-red.svg": "Completion date is out of approved range",
    "view-red.svg": "Failed is greater than 0 ISLM BOX is not checked",
    completed:
        "Performed by and total devices have been filled out. Passed devices equal total devices and failed devices equal 0. The date is filled out and within started range . Document is uploaded"
};
class ActivityCalendarTableCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFieldForCommonSearch: null,
            filterList: [],
            commonFilterParams: this.props.commonFilter || {},
            selectAll: "",
            isCancel: false,
            isOk: false,
            isLoadingDropdown: false
        };
    }

    componentDidMount = () => {
        window.addEventListener("click", e => {
            let activityCalendarTableHeaderRow = document.getElementById("activityCalendarTableHeaderRow");
            if (activityCalendarTableHeaderRow && !activityCalendarTableHeaderRow.contains(e.target) && !e.target.hasAttribute("is-dropdown-item")) {
                this.setState({
                    selectedFieldForCommonSearch: null
                });
            }
        });
        this.initiateResize(0);
        this.initiateResize(1);
        this.initiateResize(2);
        moment.monthsShort().map((month, i) => this.initiateResize(i + 3));
        this.setScrollTopToTableContainer();
        if (this.props.commonFilter) {
            if (!this.state.isOk && Object.keys(this.props.commonFilter).length !== 0) {
                let initialValue = {};
                Object.keys(this.props.commonFilter).map(fil => {
                    initialValue = { ...initialValue, [fil]: true };
                });
                this.setState({
                    isOk: initialValue,
                    isCancel: initialValue
                });
            }
        }
    };

    setScrollTopToTableContainer = () => {
        let tabContainer = document.getElementById("calendar-table-container");
        tabContainer.scrollTop = this.props.scrollTop;
    };

    componentDidUpdate = prevProps => {
        if (this.props.scrollTop !== prevProps.scrollTop) {
            this.setScrollTopToTableContainer();
        }
        if (prevProps.commonFilter !== this.props.commonFilter) {
            if (!_.isEmpty(this.props.commonFilter)) {
                const { selectAll } = [];
                let temp = selectAll;
                Object.keys(this.props.commonFilter).map(fil => {
                    if (this.props.commonFilter[fil] && this.props.commonFilter[fil].length == this.state.filterList.length) {
                        temp = { ...selectAll, [fil]: true };
                        this.setState({
                            selectAll: temp
                        });
                    }
                });
            } else {
                this.setState({
                    selectAll: {}
                });
            }
            this.setState({
                commonFilterParams: this.props.commonFilter || {},
                selectedFieldForCommonSearch: !this.props.commonFilter ? null : this.state.selectedFieldForCommonSearch
            });
        }
    };

    OnScroll = event => {
        const { updateScrollTop } = this.props;
        updateScrollTop(event.target.scrollTop);
    };

    monthIsNA = (na_start, na_end, month) => {
        let na_start_month = new Date(na_start).getMonth();
        let na_end_month = new Date(na_end).getMonth();

        if (month >= na_start_month && month <= na_end_month) {
            return true;
        }
        return false;
    };

    renderMonthlySchedule = item => {
        const { handleActivityEventClick, showCreateActivityEventSchedule, handleNAClick } = this.props;
        let tableContentHtml = moment.monthsShort().map((month, i) => {
            if (item.na_start && item.na_end) {
                if (this.monthIsNA(item.na_start, item.na_end, i)) {
                    return (
                        <td className="date-wrapper cursor-pointer">
                            <div className="icon-date-wrapper na-container" onClick={() => handleNAClick(item.id, i, item.year)}>
                                <span>NA</span>
                            </div>
                        </td>
                    );
                }
            }
            let dateObjOfMonth = this.findDatesOfMonth(item.dates, i);
            if (dateObjOfMonth) {
                if (dateObjOfMonth.icon && dateObjOfMonth.color) {
                    return (
                        <td
                            className="date-wrapper cursor-pointer"
                            style={{ background: `${dateObjOfMonth.color}` }}
                            onClick={() => handleActivityEventClick(dateObjOfMonth.id, dateObjOfMonth.schedule_id)}
                            data-for="annual_calendar"
                            data-tip={rulesForToolTip[dateObjOfMonth.icon]}
                        >
                            <div className="icon-date-wrapper">
                                <span className="icon-img">
                                    <img src={`/images/${dateObjOfMonth.icon}`} alt="" />
                                </span>
                                <span className="pos-date">
                                    {moment(new Date(dateObjOfMonth.date)).format("MM-DD-YY")} <br />
                                    {dateObjOfMonth.actual_date ? moment(new Date(dateObjOfMonth.actual_date)).format("MM-DD-YY") : null}
                                </span>
                            </div>
                        </td>
                    );
                } else if (dateObjOfMonth.color) {
                    return (
                        <td
                            className="date-wrapper cursor-pointer"
                            style={{ background: `${dateObjOfMonth.color}` }}
                            onClick={() => handleActivityEventClick(dateObjOfMonth.id, dateObjOfMonth.schedule_id)}
                            data-for="annual_calendar"
                            data-tip={rulesForToolTip.completed}
                        >
                            <div className="icon-date-wrapper">
                                <span className="pos-date">
                                    {moment(new Date(dateObjOfMonth.date)).format("MM-DD-YY")}
                                    <br />
                                    {dateObjOfMonth.actual_date ? moment(new Date(dateObjOfMonth.actual_date)).format("MM-DD-YY") : null}
                                </span>
                            </div>
                        </td>
                    );
                } else {
                    if (this.isDatePassed(new Date(dateObjOfMonth.date), item.completion_threshold, dateObjOfMonth)) {
                        return (
                            <td
                                className="date-wrapper cursor-pointer"
                                style={{
                                    background: "#F59089"
                                }}
                                onClick={() => handleActivityEventClick(dateObjOfMonth.id, dateObjOfMonth.schedule_id)}
                                data-for="annual_calendar"
                                data-tip={rulesForToolTip["plus-icn-red.svg"]}
                            >
                                <div className="icon-date-wrapper">
                                    <span className="icon-img">
                                        <img src={`/images/plus-icn-red.svg`} alt="" />
                                    </span>
                                    <span className="pos-date">
                                        {moment(new Date(dateObjOfMonth.date)).format("MM-DD-YY")}
                                        <br />
                                        {dateObjOfMonth.actual_date ? moment(new Date(dateObjOfMonth.actual_date)).format("MM-DD-YY") : null}
                                    </span>
                                </div>
                            </td>
                        );
                    } else {
                        return (
                            <td
                                className={`date-wrapper cursor-pointer`}
                                style={{
                                    background: "#BFFFBF"
                                }}
                                onClick={() => handleActivityEventClick(dateObjOfMonth.id, dateObjOfMonth.schedule_id)}
                                data-for="annual_calendar"
                                data-tip={this.hasBorder(dateObjOfMonth) ? "Add Survey in Threshold" : rulesForToolTip["plus-icn.svg"]}
                            >
                                <div className={`icon-date-wrapper ${this.hasBorder(dateObjOfMonth) ? "border-highlight" : ""}`}>
                                    <span className="icon-img">
                                        <img src={`/images/plus-icn.svg`} alt="" />
                                    </span>
                                    <span className="pos-date">
                                        {moment(new Date(dateObjOfMonth.date)).format("MM-DD-YY")}
                                        <br />
                                        {dateObjOfMonth.actual_date ? moment(new Date(dateObjOfMonth.actual_date)).format("MM-DD-YY") : null}
                                    </span>
                                </div>
                            </td>
                        );
                    }
                }
            } else {
                return <td className="cursor-pointer" onClick={() => showCreateActivityEventSchedule(item.id, i, item.year, "add")}></td>;
            }
        });
        return tableContentHtml;
    };

    isDatePassed = (date, treshold, dateObjOfMonth) => {
        let today = new Date();
        if (new Date(dateObjOfMonth.threshold_end).setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    };

    hasBorder = dateObjOfMonth => {
        return (
            new Date(dateObjOfMonth.threshold_start).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) &&
            new Date(dateObjOfMonth.threshold_end).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
        );
    };

    findDatesOfMonth = (dates, month) => {
        let dateOfMonth = null;
        dates.map((dateObj, i) => {
            if (new Date(dateObj.date).getMonth() === month) {
                dateOfMonth = dateObj;
            }
        });
        return dateOfMonth;
    };

    initiateResize = index => {
        let className = ".calendar-acticity-column" + index;
        let activityTable = document.querySelector(className);
        if (activityTable) {
            activityTable.className = activityTable.className + " resizable";
            let resizer = document.createElement("div");
            resizer.className = `resizer${index}`;
            activityTable.appendChild(resizer);
            resizer.addEventListener("mousedown", initDrag, false);
            let startX, startWidth;

            function initDrag(e) {
                e.preventDefault();
                startX = e.clientX;
                startWidth = parseInt(document.defaultView.getComputedStyle(activityTable).width, 10);
                document.documentElement.addEventListener("mousemove", doDrag, false);
                document.documentElement.addEventListener("mouseup", stopDrag, false);
            }

            function doDrag(e) {
                let currentWidth = startWidth + e.clientX - startX;
                activityTable.style.width = currentWidth + "px";
            }

            function stopDrag(e) {
                document.documentElement.removeEventListener("mousemove", doDrag, false);
                document.documentElement.removeEventListener("mouseup", stopDrag, false);
            }
        }
    };

    setSearchKeysArray = () => {
        const { tableParams = {} } = this.props;
        let returnArray = [];
        if (tableParams && tableParams.search) {
            returnArray.push(tableParams.search.toString());
        }
        if (tableParams && tableParams.filters && !_.isEmpty(tableParams.filters)) {
            const filters = Object.keys(tableParams.filters);
            for (const item of filters) {
                if (tableParams.filters[item] && tableParams.filters[item].key && tableParams.filters[item].key.length) {
                    if (tableParams.filters[item].filters[0] === "like") {
                        returnArray = returnArray.concat(tableParams.filters[item].key.toString().split("_").join("").split("%").join("").split("~"));
                    } else if (tableParams.filters[item].key.toString().includes("~")) {
                        returnArray = returnArray.concat(tableParams.filters[item].key.toString().split("~"));
                    } else {
                        returnArray.push(tableParams.filters[item].key.toString());
                    }
                }
            }
        }
        return returnArray;
    };

    filterHighlighter = searchKey => {
        let returnVal = false;
        if (this.props.tableParams && this.props.tableParams.filters) {
            Object.keys(this.props.tableParams.filters).map(fill => {
                if (fill === searchKey && this.props.tableParams.filters[searchKey].key && this.props.tableParams.filters[searchKey].key.length) {
                    returnVal = true;
                }
            });
        }
        if (this.props.tableParams && this.props.tableParams.search && this.props.tableParams.search.length) {
            returnVal = true;
        }
        return returnVal;
    };

    showCommonSearchDropDown = async (keyItem, searchKey) => {
        this.setState({
            selectedFieldForCommonSearch: this.state.selectedFieldForCommonSearch === keyItem ? null : keyItem,
            filterList: [],
            isLoadingDropdown: true
        });
        let getListParsms = { field: searchKey };
        let filterList = this.state.selectedFieldForCommonSearch === keyItem ? null : await this.props.getListForCommonFilter(getListParsms);
        this.setState({
            filterList,
            isLoadingDropdown: false
        });
    };

    renderFiltersForCommonSearch = keyItem => {
        if (this.state.selectedFieldForCommonSearch !== keyItem) {
            return null;
        }
        const { tableData } = this.props;

        let { filterList, isLoadingDropdown } = this.state;
        filterList = filterList.filter(item => item.name !== null && item.name !== "");

        let filterListToCheck = this.state.commonFilterParams[tableData.config[keyItem].searchKey]
            ? this.state.commonFilterParams[tableData.config[keyItem].searchKey]
            : [];

        return (
            <div className="dropdown-menu dropdown-table tempDrop" aria-labelledby="dropdownMenuButton">
                <div className="dropdown-item">
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                            name="example1"
                            is-dropdown-item="true"
                            checked={this.state.selectAll && this.state.selectAll[tableData.config[keyItem].searchKey]}
                            onChange={() => this.selectAllHandler(tableData.config[keyItem])}
                        />
                        <label className="custom-control-label" for="customCheck">
                            Select All
                        </label>
                    </div>
                </div>
                <hr />
                {!isLoadingDropdown ? (
                    <div className="dropdown-item drp-scroll" onScroll={e => e.stopPropagation()}>
                        {filterList.length ? (
                            filterList.map((item, i) => (
                                <div className="custom-control custom-checkbox" key={i}>
                                    {item.name !== null && item.name !== "" ? (
                                        <>
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                checked={filterListToCheck && filterListToCheck.length && filterListToCheck.includes(item.name)}
                                                id={`customCheck${i}`}
                                                name="example1"
                                                is-dropdown-item="true"
                                                onClick={e => {
                                                    this.setCommonFilterParams(item.name, tableData.config[keyItem].searchKey, keyItem);
                                                }}
                                            />
                                            <label className="custom-control-label" for={`customCheck${i}`}>
                                                {item.name} ({item.count})
                                            </label>
                                        </>
                                    ) : null}
                                </div>
                            ))
                        ) : (
                            <div className="col-md-6">No data</div>
                        )}
                    </div>
                ) : (
                    <div className="dropdown-item">
                        <Loader />
                    </div>
                )}
                {filterList.length ? (
                    <div className="btn-otr d-flex justify-content-center">
                        <button className="btn btn-create" onClick={() => this.updateCommonFilterHandler(tableData.config[keyItem].searchKey)}>
                            OK
                        </button>
                        <button
                            className="btn btn-cncl-back ml-2"
                            onClick={() =>
                                this.cancelCommonFilterHandler(
                                    tableData.config[keyItem].commonSearchKey,
                                    tableData.config[keyItem],
                                    tableData.config[keyItem].searchKey
                                )
                            }
                        >
                            Cancel
                        </button>
                    </div>
                ) : null}
            </div>
        );
    };

    cancelCommonFilterHandler = async () => {
        await this.setState({
            commonFilterParams: { ...this.props.commonFilter } || {},
            selectedFieldForCommonSearch: null
        });
    };

    updateCommonFilterHandler = searchKey => {
        const { commonFilterParams } = this.state;
        if (Object.keys(commonFilterParams).length !== 0) {
            this.props.updateCommonFilter(commonFilterParams);
            this.setState(prevState => ({
                isOk: { ...prevState.isOk, [searchKey]: true },
                isCancel: { ...prevState.isCancel, [searchKey]: true }
            }));
        }
        if (this.state.isOk[searchKey] || this.checkHasCommonFilters() || this.state.isCancel[searchKey]) {
            this.props.updateCommonFilter(this.state.commonFilterParams);
            this.setState(prevState => ({
                isOk: { ...prevState.isOk, [searchKey]: false },
                isCancel: { ...prevState.isCancel, [searchKey]: false }
            }));
        }
        this.setState({
            selectedFieldForCommonSearch: null
        });
    };

    setCommonFilterParams = async (value, searchKey, keyItem) => {
        let tempList = { ...this.state.commonFilterParams };
        if (tempList[searchKey]) {
            if (!tempList[searchKey].includes(value)) {
                tempList[searchKey].push(value);
            } else {
                tempList[searchKey] = tempList[searchKey].filter(item => item !== value);
            }
            if (!tempList[searchKey].length) delete tempList[searchKey];
        } else {
            tempList[searchKey] = [value];
        }
        let isSelectAll;
        if (tempList && tempList[searchKey] && tempList[searchKey].length === this.state.filterList.length) {
            isSelectAll = true;
        } else {
            isSelectAll = false;
        }
        this.setState(prevState => ({
            commonFilterParams: tempList,
            selectAll: { ...prevState.selectAll, [searchKey]: isSelectAll }
        }));
    };

    selectAllHandler = async keyItem => {
        const selctAll = !this.state.selectAll[keyItem.searchKey];
        let { filterList } = this.state;
        filterList = filterList.filter(item => item.name !== null && item.name !== "");
        let tempList = this.state.commonFilterParams;

        if (selctAll) {
            tempList[keyItem.searchKey] = filterList.map(item => item.name);
            this.setState(prevState => ({
                selectAll: { ...prevState.selectAll, [keyItem.searchKey]: selctAll },
                commonFilterParams: tempList
            }));
        } else {
            this.setState(prevState => ({
                selectAll: { ...prevState.selectAll, [keyItem.searchKey]: selctAll },
                commonFilterParams: {}
            }));
        }
    };

    checkHasCommonFilters = searchKey => {
        const { tableParams = null } = this.props;
        if (tableParams) {
            if (tableParams.list && tableParams.list[searchKey] && tableParams.list[searchKey].length) {
                return true;
            }
        }
        return false;
    };

    render() {
        const {
            isCalendarExpanded,
            schedules,
            tableData,
            updateWildCardFilter,
            showWildCardFilter,
            wildCardFilter,
            handleActivityEventClick,
            hasLastPerformedClick,
            wildCardFilterMonth,
            handleDeviceCountClick,
            updateWildCardFilterMonth
        } = this.props;
        let searchKeysArray = this.setSearchKeysArray();
        return (
            <div className={`file-system-sec-right ${isCalendarExpanded ? "mob-sec" : ""}`}>
                <div className="tab-btm-cover">
                    <div className={`file-calendar-cover ${isCalendarExpanded ? "cal-tab-active" : ""}`}>
                        <div className="calendar-tab-cover" id="calendar-table-container" onScroll={e => this.OnScroll(e)}>
                            <table className="calendar-table">
                                <thead>
                                    <tr id="activityCalendarTableHeaderRow">
                                        <th className={`calendar-acticity-column0 drop-fil drop-content min-width-130`}>Last performed</th>
                                        <th
                                            className={`calendar-acticity-column1 drop-fil drop-content ${
                                                this.checkHasCommonFilters("schedules.standard") ? "active-head" : ""
                                            }`}
                                        >
                                            Std
                                            <span
                                                className="dropdown-toggle"
                                                id="dropdownMenuButton"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                onClick={e => {
                                                    this.showCommonSearchDropDown("standard", "schedules.standard");
                                                }}
                                            >
                                                <span className="material-icons drop-icn">keyboard_arrow_down</span>
                                            </span>
                                            {this.renderFiltersForCommonSearch("standard")}
                                        </th>

                                        <th
                                            className={`drop-fil drop-content calendar-acticity-column2 min-width-200 ${
                                                this.checkHasCommonFilters("schedules.activity_description") ? "active-head" : ""
                                            }`}
                                        >
                                            Activity
                                            {/* <span> */}
                                            <span
                                                className="dropdown-toggle"
                                                id="dropdownMenuButton"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                onClick={e => {
                                                    this.showCommonSearchDropDown("activity_description", "schedules.activity_description");
                                                }}
                                            >
                                                <span className="material-icons drop-icn">keyboard_arrow_down</span>
                                            </span>
                                            {/* </span> */}
                                            {this.renderFiltersForCommonSearch("activity_description")}
                                        </th>
                                        {moment.monthsShort().map((month, i) => (
                                            <th
                                                className={
                                                    new Date().getMonth() === i
                                                        ? `th-active calendar-acticity-column${i + 3}`
                                                        : `calendar-acticity-column${i + 3}`
                                                }
                                                key={i}
                                            >
                                                <span>{month}</span>
                                            </th>
                                        ))}
                                    </tr>
                                    {showWildCardFilter ? (
                                        <WildCardFilter
                                            config={tableData.config}
                                            keys={tableData.keys}
                                            updateWildCardFilter={updateWildCardFilter}
                                            isNormalTable={false}
                                            actionShow={false}
                                            closView={false}
                                            filterBlue="filter-blue filters"
                                            wildCardFilter={wildCardFilter}
                                            isMonthFilter={true}
                                            wildCardFilterMonth={wildCardFilterMonth}
                                            updateWildCardFilterMonth={updateWildCardFilterMonth}
                                        />
                                    ) : null}
                                </thead>

                                <tbody>
                                    {schedules.length ? (
                                        <>
                                            <ReactTooltip id="annual_calendar" effect="solid" />
                                            {schedules.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="calendar-acticity-column">
                                                        {item.date_last_performed_id && hasLastPerformedClick ? (
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => handleActivityEventClick(item.date_last_performed_id, item.id)}
                                                            >
                                                                <Highlighter
                                                                    searchWords={
                                                                        this.filterHighlighter(tableData.config["date_last_performed"].searchKey)
                                                                            ? searchKeysArray
                                                                            : []
                                                                    }
                                                                    textToHighlight={item.date_last_performed || "-"}
                                                                    className="highlighter"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <Highlighter
                                                                searchWords={
                                                                    this.filterHighlighter(
                                                                        tableData.config["date_last_performed"] &&
                                                                            tableData.config["date_last_performed"].searchKey
                                                                    )
                                                                        ? searchKeysArray
                                                                        : []
                                                                }
                                                                textToHighlight={item.date_last_performed || "-"}
                                                            />
                                                        )}
                                                    </td>
                                                    <td
                                                        className="calendar-acticity-column"
                                                        data-for="annual_calendar"
                                                        data-tip={item.standard_tooltip}
                                                        data-place="top"
                                                    >
                                                        <Highlighter
                                                            searchWords={
                                                                this.filterHighlighter(
                                                                    tableData.config["standard"] && tableData.config["standard"].searchKey
                                                                )
                                                                    ? searchKeysArray
                                                                    : []
                                                            }
                                                            textToHighlight={item.standard || "-"}
                                                        />
                                                    </td>
                                                    <td
                                                        className="calendar-acticity-column"
                                                        data-for="annual_calendar"
                                                        data-tip={`<p class="dc-tooltip"><span class="dc-click-text">Click for Device Count</span><span>${item.activity_tooltip.trim()}</span></p>`}
                                                        data-place="top"
                                                        data-html={true}
                                                    >
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => handleDeviceCountClick(item.building_activity_id)}
                                                        >
                                                            <Highlighter
                                                                searchWords={
                                                                    this.filterHighlighter(tableData.config["activity_description"].searchKey)
                                                                        ? searchKeysArray
                                                                        : []
                                                                }
                                                                textToHighlight={item.activity_description || "-"}
                                                            />
                                                        </div>
                                                    </td>
                                                    {this.renderMonthlySchedule(item)}
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={15} className="text-center">
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivityCalendarTableCalendar;
