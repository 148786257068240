export const GET_SECTOR_REQUEST = "GET_SECTOR_REQUEST"; 
export const GET_SECTOR_SUCCESS = "GET_SECTOR_SUCCESS"; 
export const GET_SECTOR_FAILURE = "GET_SECTOR_FAILURE"; 

export const ADD_SECTOR_REQUEST = "ADD_SECTOR_REQUEST"; 
export const ADD_SECTOR_SUCCESS = "ADD_SECTOR_SUCCESS"; 
export const ADD_SECTOR_FAILURE = "ADD_SECTOR_FAILURE"; 

export const EDIT_SECTOR_BYID_REQUEST = "EDIT_SECTOR_BYID_REQUEST"; 
export const EDIT_SECTOR_BYID_SUCCESS = "EDIT_SECTOR_BYID_SUCCESS"; 
export const EDIT_SECTOR_BYID_FAILURE = "EDIT_SECTOR_BYID_FAILURE"; 

export const DELETE_SECTOR_BYID_REQUEST = "DELETE_SECTOR_BYID_REQUEST"; 
export const DELETE_SECTOR_BYID_SUCCESS = "DELETE_SECTOR_BYID_SUCCESS"; 
export const DELETE_SECTOR_BYID_FAILURE = "DELETE_SECTOR_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_SECTOR_BY_ID_REQUEST = "GET_SECTOR_BY_ID_REQUEST"
export const GET_SECTOR_BY_ID_SUCCESS = "GET_SECTOR_BY_ID_SUCCESS"
export const GET_SECTOR_BY_ID_FAILURE = "GET_SECTOR_BY_ID_FAILURE"

export const EXPORT_SECTOR_TABLE_REQUEST = "EXPORT_SECTOR_TABLE_REQUEST"
export const EXPORT_SECTOR_TABLE_SUCCESS = "EXPORT_SECTOR_TABLE_SUCCESS"
export const EXPORT_SECTOR_TABLE_FAILURE = "EXPORT_SECTOR_TABLE_FAILURE"

export const GET_ALL_SECTOR_LOG_REQUEST = "GET_ALL_SECTOR_LOG_REQUEST";
export const GET_ALL_SECTOR_LOG_SUCCESS = "GET_ALL_SECTOR_LOG_SUCCESS";
export const GET_ALL_SECTOR_LOG_FAILURE = "GET_ALL_SECTOR_LOG_FAILURE";

export const RESTORE_SECTOR_LOG_REQUEST = "RESTORE_SECTOR_LOG_REQUEST";
export const RESTORE_SECTOR_LOG_SUCCESS = "RESTORE_SECTOR_LOG_SUCCESS";
export const RESTORE_SECTOR_LOG_FAILURE = "RESTORE_SECTOR_LOG_FAILURE";

export const DELETE_SECTOR_LOG_REQUEST = "DELETE_SECTOR_LOG_REQUEST";
export const DELETE_SECTOR_LOG_SUCCESS = "DELETE_SECTOR_LOG_SUCCESS";
export const DELETE_SECTOR_LOG_FAILURE = "DELETE_SECTOR_LOG_FAILURE";

export const UPDATE_SECTOR_ENTITY_PARAMS_SUCCESS ="UPDATE_SECTOR_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SECTOR_ENTITY_PARAMS_FAILURE ="UPDATE_SECTOR_ENTITY_PARAMS_FAILURE";
