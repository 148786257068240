export const GET_PROCEDURE_REQUEST = "GET_PROCEDURE_REQUEST";
export const GET_PROCEDURE_SUCCESS = "GET_PROCEDURE_SUCCESS";
export const GET_PROCEDURE_FAILURE = "GET_PROCEDURE_FAILURE";

export const ADD_PROCEDURE_REQUEST = "ADD_PROCEDURE_REQUEST";
export const ADD_PROCEDURE_SUCCESS = "ADD_PROCEDURE_SUCCESS";
export const ADD_PROCEDURE_FAILURE = "ADD_PROCEDURE_FAILURE";

export const GET_PROCEDURE_BYID_REQUEST = "GET_PROCEDURE_BYID_REQUEST";
export const GET_PROCEDURE_BYID_SUCCESS = "GET_PROCEDURE_BYID_SUCCESS";
export const GET_PROCEDURE_BYID_FAILURE = "GET_PROCEDURE_BYID_FAILURE";

export const EDIT_PROCEDURE_BYID_REQUEST = "EDIT_PROCEDURE_BYID_REQUEST";
export const EDIT_PROCEDURE_BYID_SUCCESS = "EDIT_PROCEDURE_BYID_SUCCESS";
export const EDIT_PROCEDURE_BYID_FAILURE = "EDIT_PROCEDURE_BYID_FAILURE";

export const DELETE_PROCEDURE_BYID_REQUEST = "DELETE_PROCEDURE_BYID_REQUEST";
export const DELETE_PROCEDURE_BYID_SUCCESS = "DELETE_PROCEDURE_BYID_SUCCESS";
export const DELETE_PROCEDURE_BYID_FAILURE = "DELETE_PROCEDURE_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_PROCEDURE_BY_ID_REQUEST = "GET_PROCEDURE_BY_ID_REQUEST";
export const GET_PROCEDURE_BY_ID_SUCCESS = "GET_PROCEDURE_BY_ID_SUCCESS";
export const GET_PROCEDURE_BY_ID_FAILURE = "GET_PROCEDURE_BY_ID_FAILURE";

export const EXPORT_PROCEDURE_TABLE_REQUEST = "EXPORT_PROCEDURE_TABLE_REQUEST";
export const EXPORT_PROCEDURE_TABLE_SUCCESS = "EXPORT_PROCEDURE_TABLE_SUCCESS";
export const EXPORT_PROCEDURE_TABLE_FAILURE = "EXPORT_PROCEDURE_TABLE_FAILURE";

export const GET_ALL_PROCEDURE_LOG_REQUEST = "GET_ALL_PROCEDURE_LOG_REQUEST";
export const GET_ALL_PROCEDURE_LOG_SUCCESS = "GET_ALL_PROCEDURE_LOG_SUCCESS";
export const GET_ALL_PROCEDURE_LOG_FAILURE = "GET_ALL_PROCEDURE_LOG_FAILURE";

export const RESTORE_PROCEDURE_LOG_REQUEST = "RESTORE_PROCEDURE_LOG_REQUEST";
export const RESTORE_PROCEDURE_LOG_SUCCESS = "RESTORE_PROCEDURE_LOG_SUCCESS";
export const RESTORE_PROCEDURE_LOG_FAILURE = "RESTORE_PROCEDURE_LOG_FAILURE";

export const DELETE_PROCEDURE_LOG_REQUEST = "DELETE_PROCEDURE_LOG_REQUEST";
export const DELETE_PROCEDURE_LOG_SUCCESS = "DELETE_PROCEDURE_LOG_SUCCESS";
export const DELETE_PROCEDURE_LOG_FAILURE = "DELETE_PROCEDURE_LOG_FAILURE";

export const UPDATE_PROCEDURE_ENTITY_PARAMS_SUCCESS = "UPDATE_PROCEDURE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_PROCEDURE_ENTITY_PARAMS_FAILURE = "UPDATE_PROCEDURE_ENTITY_PARAMS_FAILURE";

export const UPLOAD_DOCUMENTS_IN_PROCEDURE_REQUEST = "UPLOAD_DOCUMENTS_IN_PROCEDURE_REQUEST";
export const UPLOAD_DOCUMENTS_IN_PROCEDURE_SUCCESS = "UPLOAD_DOCUMENTS_IN_PROCEDURE_SUCCESS";
export const UPLOAD_DOCUMENTS_IN_PROCEDURE_FAILURE = "UPLOAD_DOCUMENTS_IN_PROCEDURE_FAILURE";

export const GET_PROCEDURE_DOCUMENTS_REQUEST = "GET_PROCEDURE_DOCUMENTS_REQUEST";
export const GET_PROCEDURE_DOCUMENTS_SUCCESS = "GET_PROCEDURE_DOCUMENTS_SUCCESS";
export const GET_PROCEDURE_DOCUMENTS_FAILURE = "GET_PROCEDURE_DOCUMENTS_FAILURE";

export const REMOVE_ATTACHMENT_REQUEST = "REMOVE_ATTACHMENT_REQUEST";
export const REMOVE_ATTACHMENT_SUCCESS = "REMOVE_ATTACHMENT_SUCCESS";
export const REMOVE_ATTACHMENT_FAILURE = "REMOVE_ATTACHMENT_FAILURE";
