import React, { Component } from "react";
import BuildModalHeader from "./BuildModalHeader";
class IconsAndColorLegandsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionForDuplicateFiles: [
                { name: "Skip duplicates", value: "Skip duplicates" },
                { name: "Overwrite existing files", value: "Overwrite existing files" },
                { name: "Add as a copy", value: "Add as a copy" }
            ],
            documents: [],
            tempDocuments: [],
            diplecateFiles: "Add as a copy"
        };
    }

    componentDidMount = async () => {};

    handleAddAttachment = async e => {
        await this.setState({
            tempDocuments: [...this.state.tempDocuments, ...e.target.files]
        });
    };

    deleteAttachment = async index => {
        let fileArray = Object.values(this.state.tempDocuments);
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            await this.setState({
                tempDocuments: []
            });
        }
        await this.setState({
            tempDocuments: fileArray
        });
    };

    handleUploadFile = () => {
        const { tempDocuments, diplecateFiles } = this.state;
        const { activeDocType } = this.props;
        this.props.handleUploadFile(tempDocuments, diplecateFiles, activeDocType);
    };

    render() {
        const { onCancel } = this.props;
        return (
            <React.Fragment>
                <div className="modal color-legand-modal" role="dialog" style={{ display: "block" }} id="modalId">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <BuildModalHeader title="Icons and color legand" onCancel={onCancel} modalClass="color-legand-modal" />
                            <div className="modal-body">
                                <div className="btn-three-otr">
                                    <div className="box-layer-otr">
                                        <div className="icon-layer border-highlight" style={{ backgroundColor: "#BFFFBF" }}>
                                            <div className="badge-white badge-plus">
                                                <img alt="" src="/images/plus-icn.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">Add Survey in Threshold</div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer" style={{ backgroundColor: "#DBFCC7" }}>
                                            <div className="badge-white badge-plus">
                                                <img alt="" src="/images/plus-icn.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">Add Survey</div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer" style={{ backgroundColor: "#F59089" }}>
                                            <div className="badge-white badge-plus">
                                                <img alt="" src="/images/plus-icn-red.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">Add Overdue Survey</div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer"></div>
                                        <div className="right-content">Active / Seleted item</div>
                                    </div>
                                </div>
                                <div className="btn-three-otr btn-three-layer">
                                    <div className="box-layer-otr">
                                        <div className="icon-layer green"></div>
                                        <div className="right-content">
                                            Performed by and total devices have been filled out. Passed devices equal total devices and failed devices
                                            equal 0. The date is filled out and within started range . Document is uploaded
                                        </div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer green-warn-hash">
                                            <div className="badge-white badge-warning">
                                                <img alt="" src="/images/warning-green.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">
                                            All of the above except : Passed plus failed devices equal total devices and failed devices is greater
                                            than 0. ISLM Box is checked. Correction date is filled and correction document is loaded.
                                        </div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer green-warn-hash">
                                            <div className="badge-white badge-hash">
                                                <img alt="" src="/images/hashtag-green.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">
                                            All the same as first except: Passed plus failed devices do not equal total devices, however comments have
                                            entered. If failed devices is {">"} 0 then all of the above is required
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-three-otr btn-three-layer-1 flex-wrap">
                                    <div className="box-layer-otr">
                                        <div className="icon-layer red-warn">
                                            <div className="badge-white badge-warning">
                                                <img alt="" src="/images/warning-red.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">
                                            Failed devices is greater than 0, and missing correction date or correction documents
                                        </div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer red">
                                            <div className="badge-white badge-calendar">
                                                <img alt="" src="/images/calendar-red.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">Completion date is out of approved range</div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer red-doc">
                                            <div className="badge-white badge-attach">
                                                <img alt="" src="/images/attach-red.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">Document is not loaded</div>
                                    </div>
                                    <div className="box-layer-otr">
                                        <div className="icon-layer red-hash">
                                            <div className="badge-white badge-hash">
                                                <img alt="" src="/images/hashtag-red.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">
                                            Passed plus failed devices do not equal total devices, or failed devices do not equal corrected devices,
                                            and no comments entered.
                                        </div>
                                    </div>
                                    {/* <div className="box-layer-otr">
                                        <div className="icon-layer red">
                                            <div className="badge-white badge-view">
                                                <img alt="" src="/images/view-red.svg" />
                                            </div>
                                        </div>
                                        <div className="right-content">Failed is greater than 0 ISLM BOX is not checked</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IconsAndColorLegandsModal;
