import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import TopSlider from "../common/components/TopSlider";
import Loader from "../common/components/Loader";
import actions from "./actions";
import history from "../../config/history";
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLogbook: [],
            distinctLines: []
        };
    }

    componentDidMount = async () => {
        this.refreshLogbooksData();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.masterFilters !== this.props.masterFilters) {
            await this.refreshLogbooksData();
        }
    };

    refreshLogbooksData = async () => {
        this.props.setIsLoading(true);
        let master_filters = JSON.parse(localStorage.getItem("master_filters"));
        await this.props.getAllLogbooks(master_filters);
        const {
            logbooksReducer: {
                allLogbooksResponse: { logbooks = [] }
            }
        } = this.props;
        await this.setState({
            allLogbook: logbooks
        });
        this.setLogbookLines();
        this.props.setIsLoading(false);
    };

    setLogbookLines = async () => {
        const { allLogbook } = this.state;
        let tmpDistinctLines = [...new Set(allLogbook.map(logbook => logbook.line))];
        await this.setState({
            distinctLines: tmpDistinctLines
        });
    };

    handleSliderClick = async item => {
        let master_filters = JSON.parse(localStorage.getItem("master_filters"));
        if (master_filters.view === "trailing") {
            history.push("/trailingCalendar", { logbook: item });
        } else {
            history.push("/activityCalendar", { logbook: item });
        }
        await this.setState({
            activeLogbook: item.id
        });
    };

    render() {
        const { allLogbook, distinctLines } = this.state;
        const { isLoading } = this.props;
        return (
            <section className="cont-ara">
                <LoadingOverlay active={isLoading} spinner={<Loader />}>
                    <div className="dash">
                        <TopSlider />
                        <div className="dash-cont">
                            {distinctLines && distinctLines.length
                                ? distinctLines.map(line => (
                                      <div className="pub-ara six outer-six">
                                          {allLogbook && allLogbook.length
                                              ? allLogbook.map((item, i) => (
                                                    <>
                                                        {item.line === line ? (
                                                            <div className="items cursor-pointer" onClick={() => this.handleSliderClick(item)}>
                                                                <div className="item-head">
                                                                    <div className="icon">
                                                                        <img src="/images/blu-logo.svg" alt="" />
                                                                    </div>
                                                                    <div className="count">{i + 1}</div>
                                                                </div>
                                                                <div className="itm-cnt">
                                                                    <img
                                                                        src={(item.image && item.image.url) || `/images/${i + 1}.png`}
                                                                        className="img-resposive"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="itm-btm">
                                                                    <div className="head">
                                                                        <h2>{item.display_name || item.name}</h2>
                                                                    </div>
                                                                    <div className="lnk-mor">
                                                                        <span>
                                                                            <img src="/images/blu-rig.svg" alt="" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                ))
                                              : null}
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                </LoadingOverlay>
            </section>
        );
    }
}

const mapStateToProps = state => {
    const { logbooksReducer } = state;
    return { logbooksReducer };
};

export default connect(mapStateToProps, { ...actions })(index);
