export const GET_USER_PERMISSIONS_REQUEST = "GET_USER_PERMISSIONS_REQUEST";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_FAILURE = "GET_USER_PERMISSIONS_FAILURE";

export const ADD_USER_PERMISSIONS_REQUEST = "ADD_USER_PERMISSIONS_REQUEST";
export const ADD_USER_PERMISSIONS_SUCCESS = "ADD_USER_PERMISSIONS_SUCCESS";
export const ADD_USER_PERMISSIONS_FAILURE = "ADD_USER_PERMISSIONS_FAILURE";

export const GET_USER_PERMISSIONS_BYID_REQUEST = "GETUSER_PERMISSIONS_BYID_REQUEST";
export const GET_USER_PERMISSIONS_BYID_SUCCESS = "GETUSER_PERMISSIONS_BYID_SUCCESS";
export const GET_USER_PERMISSIONS_BYID_FAILURE = "GETUSER_PERMISSIONS_BYID_FAILURE";

export const EDIT_USER_PERMISSIONS_BYID_REQUEST = "EDIT_USER_PERMISSIONS_BYID_REQUEST";
export const EDIT_USER_PERMISSIONS_BYID_SUCCESS = "EDIT_USER_PERMISSIONS_BYID_SUCCESS";
export const EDIT_USER_PERMISSIONS_BYID_FAILURE = "EDIT_USER_PERMISSIONS_BYID_FAILURE";

export const DELETE_USER_PERMISSIONS_BYID_REQUEST = "DELETE_ USER_PERMISSIONS_BYID_REQUEST";
export const DELETE_USER_PERMISSIONS_BYID_SUCCESS = "DELETE_ USER_PERMISSIONS_BYID_SUCCESS";
export const DELETE_USER_PERMISSIONS_BYID_FAILURE = "DELETE_ USER_PERMISSIONS_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_USER_PERMISSIONS_BY_ID_REQUEST = "GET_USER_PERMISSIONS_BY_ID_REQUEST"
export const GET_USER_PERMISSIONS_BY_ID_SUCCESS = "GET_USER_PERMISSIONS_BY_ID_SUCCESS"
export const GET_USER_PERMISSIONS_BY_ID_FAILURE = "GET_USER_PERMISSIONS_BY_ID_FAILURE"

export const EXPORT_USER_PERMISSIONS_TABLE_REQUEST = "EXPORT_USER_PERMISSIONS_TABLE_REQUEST"
export const EXPORT_USER_PERMISSIONS_TABLE_SUCCESS = "EXPORT_USER_PERMISSIONS_TABLE_SUCCESS"
export const EXPORT_USER_PERMISSIONS_TABLE_FAILURE = "EXPORT_USER_PERMISSIONS_TABLE_FAILURE"

export const GET_ALL_USER_PERMISSIONS_LOG_REQUEST = "GET_ALL_USER_PERMISSIONS_LOG_REQUEST";
export const GET_ALL_USER_PERMISSIONS_LOG_SUCCESS = "GET_ALL_USER_PERMISSIONS_LOG_SUCCESS";
export const GET_ALL_USER_PERMISSIONS_LOG_FAILURE = "GET_ALL_USER_PERMISSIONS_LOG_FAILURE";

export const RESTORE_USER_PERMISSIONS_LOG_REQUEST = "RESTORE_USER_PERMISSIONS_LOG_REQUEST";
export const RESTORE_USER_PERMISSIONS_LOG_SUCCESS = "RESTORE_USER_PERMISSIONS_LOG_SUCCESS";
export const RESTORE_USER_PERMISSIONS_LOG_FAILURE = "RESTORE_USER_PERMISSIONS_LOG_FAILURE";

export const DELETE_USER_PERMISSIONS_LOG_REQUEST = "DELETE_USER_PERMISSIONS_LOG_REQUEST";
export const DELETE_USER_PERMISSIONS_LOG_SUCCESS = "DELETE_USER_PERMISSIONS_LOG_SUCCESS";
export const DELETE_USER_PERMISSIONS_LOG_FAILURE = "DELETE_USER_PERMISSIONS_LOG_FAILURE";

export const UPDATE_USER_PERMISSIONS_ENTITY_PARAMS_SUCCESS ="UPDATE_USER_PERMISSIONS_ENTITY_PARAMS_SUCCESS";
export const UPDATE_USER_PERMISSIONS_ENTITY_PARAMS_FAILURE ="UPDATE_USER_PERMISSIONS_ENTITY_PARAMS_FAILURE";

export const GET_TEMPLATES_BYID_REQUEST = "GET_TEMPLATES_BYID_REQUEST";
export const GET_TEMPLATES_BYID_SUCCESS = "GET_TEMPLATES_BYID_SUCCESS";
export const GET_TEMPLATES_BYID_FAILURE = "GET_TEMPLATES_BYID_FAILURE";

export const GET_USER_LIST_FOR_PERMISSIONS_REQUEST = "GET_USER_LIST_FOR_PERMISSIONS_REQUEST";
export const GET_USER_LIST_FOR_PERMISSIONS_SUCCESS = "GET_USER_LIST_FOR_PERMISSIONS_SUCCESS";
export const GET_USER_LIST_FOR_PERMISSIONS_FAILURE = "GET_USER_LIST_FOR_PERMISSIONS_FAILURE";
