export const GET_BUILDING_USERS_REQUEST = "GET_BUILDING_USERS_REQUEST";
export const GET_BUILDING_USERS_SUCCESS = "GET_BUILDING_USERS_SUCCESS";
export const GET_BUILDING_USERS_FAILURE = "GET_BUILDING_USERS_FAILURE";

export const ADD_BUILDING_USERS_REQUEST = "ADD_BUILDING_USERS_REQUEST";
export const ADD_BUILDING_USERS_SUCCESS = "ADD_BUILDING_USERS_SUCCESS";
export const ADD_BUILDING_USERS_FAILURE = "ADD_BUILDING_USERS_FAILURE";

export const GET_BUILDING_USERS_BYID_REQUEST = "GETUSERS_BYID_REQUEST";
export const GET_BUILDING_USERS_BYID_SUCCESS = "GETUSERS_BYID_SUCCESS";
export const GET_BUILDING_USERS_BYID_FAILURE = "GETUSERS_BYID_FAILURE";

export const EDIT_BUILDING_USERS_BYID_REQUEST = "EDIT_BUILDING_USERS_BYID_REQUEST";
export const EDIT_BUILDING_USERS_BYID_SUCCESS = "EDIT_BUILDING_USERS_BYID_SUCCESS";
export const EDIT_BUILDING_USERS_BYID_FAILURE = "EDIT_BUILDING_USERS_BYID_FAILURE";

export const DELETE_BUILDING_USERS_BYID_REQUEST = "DELETE_ USERS_BYID_REQUEST";
export const DELETE_BUILDING_USERS_BYID_SUCCESS = "DELETE_ USERS_BYID_SUCCESS";
export const DELETE_BUILDING_USERS_BYID_FAILURE = "DELETE_ USERS_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_BUILDING_USERS_BY_ID_REQUEST = "GET_BUILDING_USERS_BY_ID_REQUEST"
export const GET_BUILDING_USERS_BY_ID_SUCCESS = "GET_BUILDING_USERS_BY_ID_SUCCESS"
export const GET_BUILDING_USERS_BY_ID_FAILURE = "GET_BUILDING_USERS_BY_ID_FAILURE"

export const EXPORT_BUILDING_USERS_TABLE_REQUEST = "EXPORT_BUILDING_USERS_TABLE_REQUEST"
export const EXPORT_BUILDING_USERS_TABLE_SUCCESS = "EXPORT_BUILDING_USERS_TABLE_SUCCESS"
export const EXPORT_BUILDING_USERS_TABLE_FAILURE = "EXPORT_BUILDING_USERS_TABLE_FAILURE"

export const GET_ALL_BUILDING_USERS_LOG_REQUEST = "GET_ALL_BUILDING_USERS_LOG_REQUEST";
export const GET_ALL_BUILDING_USERS_LOG_SUCCESS = "GET_ALL_BUILDING_USERS_LOG_SUCCESS";
export const GET_ALL_BUILDING_USERS_LOG_FAILURE = "GET_ALL_BUILDING_USERS_LOG_FAILURE";

export const RESTORE_BUILDING_USERS_LOG_REQUEST = "RESTORE_BUILDING_USERS_LOG_REQUEST";
export const RESTORE_BUILDING_USERS_LOG_SUCCESS = "RESTORE_BUILDING_USERS_LOG_SUCCESS";
export const RESTORE_BUILDING_USERS_LOG_FAILURE = "RESTORE_BUILDING_USERS_LOG_FAILURE";

export const DELETE_BUILDING_USERS_LOG_REQUEST = "DELETE_BUILDING_USERS_LOG_REQUEST";
export const DELETE_BUILDING_USERS_LOG_SUCCESS = "DELETE_BUILDING_USERS_LOG_SUCCESS";
export const DELETE_BUILDING_USERS_LOG_FAILURE = "DELETE_BUILDING_USERS_LOG_FAILURE";

export const UPDATE_BUILDING_USERS_ENTITY_PARAMS_SUCCESS ="UPDATE_BUILDING_USERS_ENTITY_PARAMS_SUCCESS";
export const UPDATE_BUILDING_USERS_ENTITY_PARAMS_FAILURE ="UPDATE_BUILDING_USERS_ENTITY_PARAMS_FAILURE";

