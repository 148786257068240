export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const ADD_TEMPLATES_REQUEST = "ADD_TEMPLATES_REQUEST";
export const ADD_TEMPLATES_SUCCESS = "ADD_TEMPLATES_SUCCESS";
export const ADD_TEMPLATES_FAILURE = "ADD_TEMPLATES_FAILURE";

export const GET_TEMPLATES_BYID_REQUEST = "GETTEMPLATES_BYID_REQUEST";
export const GET_TEMPLATES_BYID_SUCCESS = "GETTEMPLATES_BYID_SUCCESS";
export const GET_TEMPLATES_BYID_FAILURE = "GETTEMPLATES_BYID_FAILURE";

export const EDIT_TEMPLATES_BYID_REQUEST = "EDIT_TEMPLATES_BYID_REQUEST";
export const EDIT_TEMPLATES_BYID_SUCCESS = "EDIT_TEMPLATES_BYID_SUCCESS";
export const EDIT_TEMPLATES_BYID_FAILURE = "EDIT_TEMPLATES_BYID_FAILURE";

export const DELETE_TEMPLATES_BYID_REQUEST = "DELETE_ TEMPLATES_BYID_REQUEST";
export const DELETE_TEMPLATES_BYID_SUCCESS = "DELETE_ TEMPLATES_BYID_SUCCESS";
export const DELETE_TEMPLATES_BYID_FAILURE = "DELETE_ TEMPLATES_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_TEMPLATES_BY_ID_REQUEST = "GET_TEMPLATES_BY_ID_REQUEST"
export const GET_TEMPLATES_BY_ID_SUCCESS = "GET_TEMPLATES_BY_ID_SUCCESS"
export const GET_TEMPLATES_BY_ID_FAILURE = "GET_TEMPLATES_BY_ID_FAILURE"

export const EXPORT_TEMPLATES_TABLE_REQUEST = "EXPORT_TEMPLATES_TABLE_REQUEST"
export const EXPORT_TEMPLATES_TABLE_SUCCESS = "EXPORT_TEMPLATES_TABLE_SUCCESS"
export const EXPORT_TEMPLATES_TABLE_FAILURE = "EXPORT_TEMPLATES_TABLE_FAILURE"

export const GET_ALL_TEMPLATES_LOG_REQUEST = "GET_ALL_TEMPLATES_LOG_REQUEST";
export const GET_ALL_TEMPLATES_LOG_SUCCESS = "GET_ALL_TEMPLATES_LOG_SUCCESS";
export const GET_ALL_TEMPLATES_LOG_FAILURE = "GET_ALL_TEMPLATES_LOG_FAILURE";

export const RESTORE_TEMPLATES_LOG_REQUEST = "RESTORE_TEMPLATES_LOG_REQUEST";
export const RESTORE_TEMPLATES_LOG_SUCCESS = "RESTORE_TEMPLATES_LOG_SUCCESS";
export const RESTORE_TEMPLATES_LOG_FAILURE = "RESTORE_TEMPLATES_LOG_FAILURE";

export const DELETE_TEMPLATES_LOG_REQUEST = "DELETE_TEMPLATES_LOG_REQUEST";
export const DELETE_TEMPLATES_LOG_SUCCESS = "DELETE_TEMPLATES_LOG_SUCCESS";
export const DELETE_TEMPLATES_LOG_FAILURE = "DELETE_TEMPLATES_LOG_FAILURE";

export const UPDATE_TEMPLATES_ENTITY_PARAMS_SUCCESS ="UPDATE_TEMPLATES_ENTITY_PARAMS_SUCCESS";
export const UPDATE_TEMPLATES_ENTITY_PARAMS_FAILURE ="UPDATE_TEMPLATES_ENTITY_PARAMS_FAILURE";

