/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import history from "../../../config/history";
import { WDS_SOCKET_PATH } from "../../../config/contstants";
import { checkPermission, toggleTooltip } from "../../../config/utils";
import ToastMsg from "../../common/ToastMessage";
import commonActions from "../actions";
import Loader from "./Loader";
import Portal from "./Portal";
import AppliedMasterFiltersModal from "./AppliedMasterFiltersModal";
import TopSlider from "./TopSlider";

let defaultMasterFilters = {
    logbook_ids: [],
    building_ids: [],
    building_type_ids: [],
    campus_ids: [],
    sector_ids: [],
    client_ids: [],
    consultancy_ids: [],
    view: "trailing",
    annual_years: [`${new Date().getFullYear()}`]
};
class SideNav extends Component {
    state = {
        asideClass: "show",
        navbarClass: "",
        settingClass: "",
        notifications: JSON.parse(localStorage.getItem("user_notification")),
        isLoadingNotifications: true,
        showNotificationsDropDown: false,
        showProfileDropDown: false,
        showAppliedMasterFilterModal: false
    };

    componentDidMount = async () => {
        if (WDS_SOCKET_PATH && WDS_SOCKET_PATH.length) {
            const userId = localStorage.getItem("user_id");
            this.createWebsocketConnection(userId || null, WDS_SOCKET_PATH);
        }
        this.getUnReadNotifications(true);
        await this.setState({
            isLoadingNotifications: false
        });
        toggleTooltip();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        ReactTooltip.rebuild();
    };

    logOut = () => {
        localStorage.clear();
        history.push("/login");
    };

    pageChange = pageName => {
        history.push("/" + pageName);
    };

    settingHandler = async () => {
        document.getElementById("navbarSupportedContent") && document.getElementById("navbarSupportedContent").classList.remove("show");
        document.getElementById("colbuttoninto") && document.getElementById("colbuttoninto").classList.add("icon-sta");
    };

    closeHandler = async () => {
        document.getElementById("setting") && document.getElementById("setting").classList.remove("show");
        document.getElementById("scheduling") && document.getElementById("scheduling").classList.remove("show");
        document.getElementById("reports") && document.getElementById("reports").classList.remove("show");
        document.getElementById("others") && document.getElementById("others").classList.remove("show");
        document.getElementById("userSession") && document.getElementById("userSession").classList.remove("show");
        document.getElementById("charts") && document.getElementById("charts").classList.remove("show");
        document.getElementById("colbuttoninto") && document.getElementById("colbuttoninto").classList.remove("icon-sta");
    };

    createWebsocketConnection = (roomId, WDS_SOCKET_PATH) => {
        let socket = new WebSocket(WDS_SOCKET_PATH);
        let self = this;
        socket.onopen = function (event) {
            console.log("WebSocket is connected.");
            const msg = {
                command: "subscribe",
                identifier: JSON.stringify({
                    room_id: roomId,
                    channel: "Api::V1::NotificationsChannel"
                })
            };
            socket.send(JSON.stringify(msg));
        };
        socket.onclose = function (event) {
            console.log("WebSocket is closed.");
        };
        socket.onmessage = function (event) {
            const response = event.data;
            const msg = JSON.parse(response);
            if (msg.type === "ping") {
                return;
            }
            console.log("FROM RAILS: ", msg);
            let currentNotifications = JSON.parse(localStorage.getItem("user_notification"));
            if (msg.message) {
                currentNotifications.push(msg.message.notification);
                localStorage.setItem("user_notification", JSON.stringify(currentNotifications || []));
                self.setState({
                    notifications: currentNotifications
                });
                ToastMsg("You have a new notification", "info");
            }
        };
        socket.onerror = function (error) {
            console.log("WebSocket Error: " + error);
        };
    };

    handleNotificationIconClick = async () => {
        const { showNotificationsDropDown } = this.state;
        await this.setState({
            showNotificationsDropDown: !showNotificationsDropDown,
            showProfileDropDown: false
        });
        if (!showNotificationsDropDown) {
            await this.setState({
                isLoadingNotifications: true
            });
            this.getUnReadNotifications();
            await this.setState({
                isLoadingNotifications: false
            });
        }
    };

    toggleShowProfileDropDown = () => {
        const { showProfileDropDown } = this.state;
        this.setState({
            showProfileDropDown: !showProfileDropDown,
            showNotificationsDropDown: false
        });
    };

    getUnReadNotifications = async (login = false) => {
        await this.props.getUnReadNotifications(login);
        const {
            commonReducer: {
                getUnReadNotificationsResponse: { notifications, success }
            }
        } = this.props;
        if (success) {
            localStorage.setItem("user_notification", JSON.stringify(notifications));
            this.setState({
                notifications: notifications
            });
        }
    };

    handleDeleteNotification = async (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        await this.props.deleteUnReadNotifications(id);
        this.getUnReadNotifications();
    };

    onNotificationClick = async notification => {
        await this.props.readNotification(notification.id);
        await this.setState({
            showNotificationsDropDown: false
        });
        this.getUnReadNotifications();
        history.push(notification.link);
    };

    handleShowAllNotificationClick = async id => {
        await this.setState({
            showNotificationsDropDown: false
        });
        history.push("/notifications");
    };

    toggleAppliedMasterFilterModal = item => {
        const { showAppliedMasterFilterModal } = this.state;
        this.setState({
            showAppliedMasterFilterModal: !showAppliedMasterFilterModal
        });
    };

    renderAppliedMasterFiltersModal = () => {
        const { showAppliedMasterFilterModal } = this.state;
        if (!showAppliedMasterFilterModal) return null;
        return (
            <Portal
                body={<AppliedMasterFiltersModal onCancel={this.toggleAppliedMasterFilterModal} />}
                onCancel={this.toggleAppliedMasterFilterModal}
            />
        );
    };

    resetMasterFilter = async () => {
        const { setMasterFilter } = this.props;
        ReactTooltip.hide();
        await localStorage.setItem("master_filters", JSON.stringify(defaultMasterFilters));
        await localStorage.setItem("master_filters_applied", JSON.stringify(defaultMasterFilters));
        await this.setState({
            selectedFiterDropdown: null
        });
        setMasterFilter(defaultMasterFilters);
    };

    viewMyProfile = () => {
        const userId = localStorage.getItem("user_id");
        this.toggleShowProfileDropDown();
        history.push(`/user/edit/${userId}`, { prevPagePath: this.props.location.pathname });
    };

    render() {
        let userName = localStorage.getItem("userName") || "User";
        const { notifications, isLoadingNotifications, showNotificationsDropDown, showProfileDropDown } = this.state;
        const { toggleMasterFilter, isMasterFiltered, showMasterFilter } = this.props;
        let notificationCount =
            (this.props.commonReducer.getUnReadNotificationsResponse && this.props.commonReducer.getUnReadNotificationsResponse.count) || 0;
        return (
            <React.Fragment>
                <header>
                    <ReactTooltip effect="solid" id="common_nav" />
                    <ToastContainer />
                    <nav className="navbar">
                        <div className="mnu-ico">
                            <button
                                className={`navbar-toggler`}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="true"
                                aria-label="Toggle navigation"
                                id="colbuttoninto"
                            >
                                <img src="/images/menu.svg" className="close" alt="" />
                                <img src="/images/clear.svg" className="open" alt="" onClick={() => this.closeHandler()} />
                            </button>
                        </div>
                        <span className="navbar-brand">
                            <img
                                className="cursor-pointer"
                                src="/images/logo-inner.svg"
                                alt=""
                                onClick={() => history.push("/dashboard")}
                                data-toggle="collapse"
                                aria-expanded="true"
                                data-target={"#navbarSupportedContent"}
                                aria-label="Toggle navigation"
                            />
                        </span>

                        <div className="mnu-info ml-auto drop-grp">
                            {!showMasterFilter && isMasterFiltered ? (
                                <div className="btn-grp btn-grp-top">
                                    <button
                                        className="btn"
                                        onClick={() => this.toggleAppliedMasterFilterModal()}
                                        data-tip="View Applied Filters"
                                        data-for="common_nav"
                                    >
                                        <img src="/images/wild-card.svg" alt="" />
                                    </button>
                                    <button
                                        className="btn active"
                                        onClick={() => this.resetMasterFilter()}
                                        data-tip="Reset Master Filters"
                                        data-for="common_nav"
                                    >
                                        <img src="/images/reset-wild-card.svg" alt="" />
                                    </button>
                                </div>
                            ) : null}

                            <div className="custom-drop icon-drop show">
                                <button
                                    className="btn btn-secondary fltr dropdown-toggle"
                                    onClick={() => toggleMasterFilter()}
                                    data-tip="Master Filters"
                                    data-for="common_nav"
                                >
                                    <img src="/images/filter-icon.svg" alt="" />
                                </button>
                                <span className="top-slider-icon" data-tip="Display Logbooks" data-for="common_nav">
                                    <i
                                        className="material-icons"
                                        data-toggle="collapse"
                                        data-target="#collapseExample"
                                        aria-expanded="true"
                                        aria-controls="collapseExample"
                                    >
                                        expand_more
                                    </i>
                                </span>
                                <button
                                    type="button"
                                    className="btn btn-secondary dropdown-toggle"
                                    onClick={() => this.handleNotificationIconClick()}
                                    data-tip="Notifications"
                                    data-for="common_nav"
                                >
                                    <i>
                                        <img src="/images/bell-icon.svg" alt="" />
                                        <span className="circle">{notificationCount}</span>
                                    </i>
                                </button>
                                {showNotificationsDropDown ? (
                                    <div className="dropdown-menu dropdown-menu-right notification-view show">
                                        <ul>
                                            {isLoadingNotifications ? (
                                                <li>
                                                    <Loader />
                                                </li>
                                            ) : notifications && notifications.length ? (
                                                notifications.map((item, i) => (
                                                    <li className="cursor-pointer" key={i} onClick={() => this.onNotificationClick(item)}>
                                                        <div className="icon">
                                                            <img src="/images/notification.svg" alt="" />
                                                        </div>
                                                        <div className="content">
                                                            <strong>{item.body || "-"}</strong>

                                                            <div className="time">{item.created_at || "-"}</div>
                                                        </div>
                                                        <i onClick={e => this.handleDeleteNotification(e, item.id)}>
                                                            <img src="/images/delete.svg" alt="" />
                                                        </i>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>
                                                    <div className="icon">
                                                        <img src="/images/notification.svg" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <strong>{"No New Notifications!"}</strong>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                        <div className="show-all-outer">
                                            <span className="btn-primary cursor-pointer" onClick={() => this.handleShowAllNotificationClick()}>
                                                Show all items
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <ul className="prf-lst">
                                <li className="nav-item dropdown show">
                                    <div
                                        className="nav-link dropdown-toggle "
                                        id="profile-info"
                                        role="button"
                                        onClick={() => this.toggleShowProfileDropDown()}
                                    >
                                        <div className="prf-img">
                                            <img src="/images/profile-icon.svg" alt="" />
                                        </div>
                                        <div className="pdf-name">{userName}</div>
                                    </div>
                                    {showProfileDropDown ? (
                                        <div className="dropdown-menu profile-view show" aria-labelledby="profile-info">
                                            {checkPermission("forms", "users", "edit") ? (
                                                <button onClick={() => this.viewMyProfile()}>
                                                    <span>My Profile</span>
                                                </button>
                                            ) : null}
                                            <button>
                                                <span>Reset Password</span>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    this.logOut();
                                                }}
                                            >
                                                <span>Logout</span>
                                            </button>
                                        </div>
                                    ) : null}
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                <aside className={`sidenav navbar-collapse fixed collapse ${"show"}`} id="navbarSupportedContent">
                    <ul className="navbar">
                        {checkPermission("menu", "dashboard", null) ? (
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    onClick={() => {
                                        this.pageChange("dashboard");
                                    }}
                                >
                                    <div className="icn-sec">
                                        <img src="/images/Dashboard.svg" alt="" />
                                    </div>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                        ) : null}
                        {checkPermission("menu", "logbooks", null) ? (
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    onClick={() => {
                                        this.pageChange("logbooksmain");
                                    }}
                                >
                                    <div className="icn-sec">
                                        <img src="/images/user.svg" alt="" />
                                    </div>
                                    <span>Logbooks</span>
                                </a>
                            </li>
                        ) : null}
                        {checkPermission("menu", "reports", null) ? (
                            <li className="nav-item" onClick={e => this.settingHandler(e)}>
                                <a
                                    className="nav-link"
                                    href="#reports"
                                    data-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="reports"
                                >
                                    <div className="icn-sec">
                                        <img src="/images/Report.svg" alt="" />
                                    </div>
                                    <span>Reports</span>
                                </a>
                                <div className="viw-sub">
                                    <div className="collapse" id="reports">
                                        <ul className="view">
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/thresholdStart");
                                                    }}
                                                    className=""
                                                >
                                                    Threshold Start
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/thresholdMiddle");
                                                    }}
                                                    className=""
                                                >
                                                    Threshold Middle
                                                </a>
                                            </li>

                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/dueNextDay");
                                                    }}
                                                    className=""
                                                >
                                                    Due Next Day
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/dueToday");
                                                    }}
                                                    className=""
                                                >
                                                    Due Today
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/threshold3DayeEnd");
                                                    }}
                                                    className=""
                                                >
                                                    Threshold 3 Day End
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/thresholdWindow");
                                                    }}
                                                    className=""
                                                >
                                                    Threshold Window
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/currentWeek");
                                                    }}
                                                    className=""
                                                >
                                                    Current Week
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/nextWeek");
                                                    }}
                                                    className=""
                                                >
                                                    Next Week
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/nextMonth");
                                                    }}
                                                    className=""
                                                >
                                                    Next Month
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/completed");
                                                    }}
                                                    className=""
                                                >
                                                    Completed
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/overDue");
                                                    }}
                                                    className=""
                                                >
                                                    Over Due
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/incomplete");
                                                    }}
                                                    className=""
                                                >
                                                    Incomplete
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/nonCompliant");
                                                    }}
                                                    className=""
                                                >
                                                    Non-Compliant
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    onClick={() => {
                                                        this.pageChange("reports/trailingViewReport");
                                                    }}
                                                    className=""
                                                >
                                                    Trailing View Report
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#userSession"
                                                    data-toggle="collapse"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="setting1"
                                                    className="nav-link nav-link-sub"
                                                >
                                                    User Session
                                                </a>
                                                <div className="viw-sub sub-2">
                                                    <div className="collapse" id="userSession">
                                                        <ul className="view">
                                                            <li>
                                                                <a
                                                                    onClick={() => {
                                                                        this.pageChange("comingSoon");
                                                                    }}
                                                                    className=""
                                                                >
                                                                    User Activity Log
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ) : null}

                        {checkPermission("menu", "scheduling", null) ? (
                            <li className="nav-item" onClick={e => this.settingHandler(e)}>
                                <a
                                    href="#scheduling"
                                    data-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="scheduling"
                                    className="nav-link"
                                >
                                    <div className="icn-sec">
                                        <img src="/images/schedule-icon.svg" alt="" />
                                    </div>
                                    <span>Scheduling</span>
                                </a>
                                <div className="viw-sub">
                                    <div className="collapse" id="scheduling">
                                        <ul className="view">
                                            {checkPermission("menu", "scheduling", "define_building_logbook_schedule") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("comingSoon");
                                                        }}
                                                        className=""
                                                    >
                                                        Define Building Logbook Schedule
                                                    </a>
                                                </li>
                                            ) : null}

                                            {checkPermission("menu", "scheduling", "create_surveys") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("comingSoon");
                                                        }}
                                                        className=""
                                                    >
                                                        Create Surveys
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "scheduling", "define_building_activity_schedule") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("buildingActivities");
                                                        }}
                                                        className=""
                                                    >
                                                        Define Building Activity Schedule
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "scheduling", "edit_building_activity_properties") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("allActivityCalendar");
                                                        }}
                                                        className=""
                                                    >
                                                        Edit Building Activity Properties
                                                    </a>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ) : null}
                        {checkPermission("menu", "settings", null) ? (
                            <li className="nav-item" onClick={e => this.settingHandler(e)}>
                                <a
                                    href="#setting"
                                    data-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="setting"
                                    className="nav-link"
                                >
                                    <div className="icn-sec">
                                        <img src="/images/setting.svg" alt="" />
                                    </div>
                                    <span>Settings</span>
                                </a>
                                <div className="viw-sub">
                                    <div className="collapse" id="setting">
                                        <ul className="view">
                                            {checkPermission("menu", "settings", "consultancies") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("consultancies");
                                                        }}
                                                        className=""
                                                    >
                                                        Consultancies
                                                    </a>
                                                </li>
                                            ) : null}

                                            {checkPermission("menu", "settings", "clients") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("clients");
                                                        }}
                                                        className=""
                                                    >
                                                        Clients
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "sectors") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("sectors");
                                                        }}
                                                        className=""
                                                    >
                                                        Sectors
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "campuses") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("campuses");
                                                        }}
                                                        className=""
                                                    >
                                                        Campuses
                                                    </a>
                                                </li>
                                            ) : null}

                                            {checkPermission("menu", "settings", "buildings") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("buildings");
                                                        }}
                                                        className=""
                                                    >
                                                        Buildings
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "floors") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("floors");
                                                        }}
                                                        className=""
                                                    >
                                                        Floors
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "assets") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("assets");
                                                        }}
                                                        className=""
                                                    >
                                                        Assets
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "asset_conditions") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("assetConditions");
                                                        }}
                                                        className=""
                                                    >
                                                        Asset Conditions
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "master_logbooks") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("logbooks");
                                                        }}
                                                        className=""
                                                    >
                                                        Logbooks
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "activities") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("activities");
                                                        }}
                                                        className=""
                                                    >
                                                        Activities
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "procedures") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("procedures");
                                                        }}
                                                        className=""
                                                    >
                                                        Procedures
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "forms") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("forms");
                                                        }}
                                                        className=""
                                                    >
                                                        Forms
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "deeming_agencies") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("deeming_agencies");
                                                        }}
                                                        className=""
                                                    >
                                                        Deeming Agencies
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "frequencies") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("frequencies");
                                                        }}
                                                        className=""
                                                    >
                                                        Frequencies
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "users") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("users");
                                                        }}
                                                        className=""
                                                    >
                                                        Users
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "user_permissions") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("userpermissions");
                                                        }}
                                                        className=""
                                                    >
                                                        User Permissions
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "templates") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("templates");
                                                        }}
                                                        className=""
                                                    >
                                                        Templates
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "notifications") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("notifications");
                                                        }}
                                                        className=""
                                                    >
                                                        Notifications
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "building_types") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("building_types");
                                                        }}
                                                        className=""
                                                    >
                                                        Building Types
                                                    </a>
                                                </li>
                                            ) : null}
                                            {checkPermission("menu", "settings", "email_logs") ? (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            this.pageChange("emails/inbox");
                                                        }}
                                                        className=""
                                                    >
                                                        Emails
                                                    </a>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ) : null}

                        <li className="nav-item">
                            <a
                                className="nav-link"
                                onClick={() => {
                                    this.logOut();
                                }}
                            >
                                <div className="icn-sec">
                                    <img src="/images/logout.svg" alt="" />
                                </div>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </aside>
                {this.renderAppliedMasterFiltersModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { commonReducer } = state;
    return { commonReducer };
};

export default withRouter(connect(mapStateToProps, { ...commonActions })(SideNav));
