import React, { Component } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import ToastMsg from "../ToastMessage";
import GlobalSearch from "./GlobalSearch";
import history from "../../../config/history";
import SendEmailModal from "../../email/components/sendEmailModal";
import Portal from "./Portal";

class ActivityCalendarTableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yearsList: [
                { id: "2020", name: "2020" },
                { id: "2021", name: "2021" },
                { id: "2022", name: "2022" },
                { id: "2023", name: "2023" },
                { id: "2024", name: "2024" },
                { id: "2025", name: "2025" },
                { id: "2026", name: "2026" },
                { id: "2027", name: "2027" },
                { id: "2028", name: "2028" },
                { id: "2029", name: "2029" },
                { id: "2030", name: "2030" }
            ],
            selectedYears: [{ id: new Date().getFullYear(), name: new Date().getFullYear() }],
            selectedYearList: [{ id: new Date().getFullYear(), name: new Date().getFullYear() }],
            exportLoading: false,
            showYearSelect: true,
            showSendEmailModal: false
        };
    }

    componentDidMount = async () => {
        const { params } = this.props;
        let tempSelectedYears = params.years && params.years.map(year => ({ id: year, name: year }));
        await this.setState({
            selectedYears: tempSelectedYears
        });
        await this.setYearsList();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.masterFilters !== this.props.masterFilters) {
            await this.setYearsList();
        }
    };

    setYearsList = async () => {
        let defaultMasterFilters = {
            logbook_ids: [],
            building_ids: [],
            building_type_ids: [],
            campus_ids: [],
            sector_ids: [],
            client_ids: [],
            consultancy_ids: [],
            view: "trailing",
            annual_years: [`${new Date().getFullYear()}`]
        };
        let tempYearsList = [
            { id: "2020", name: "2020" },
            { id: "2021", name: "2021" },
            { id: "2022", name: "2022" },
            { id: "2023", name: "2023" },
            { id: "2024", name: "2024" },
            { id: "2025", name: "2025" },
            { id: "2026", name: "2026" },
            { id: "2027", name: "2027" },
            { id: "2028", name: "2028" },
            { id: "2029", name: "2029" },
            { id: "2030", name: "2030" }
        ];
        let master_filters = JSON.parse(localStorage.getItem("master_filters"));
        if (!_.isEqual(defaultMasterFilters, master_filters)) {
            if (master_filters && master_filters.view === "annual")
                await this.setState({
                    yearsList: master_filters.annual_years && master_filters.annual_years.map(year => ({ id: year, name: year })),
                    selectedYears: master_filters.annual_years && master_filters.annual_years.map(year => ({ id: year, name: year }))
                });
        } else {
            await this.setState({
                yearsList: tempYearsList
            });
        }
        return true;
    };

    onYearSelect = async selectedList => {
        let tempYearList = [];
        tempYearList = selectedList.map(item => item.id);
        selectedList = _.orderBy(selectedList, ["name"], ["asc"]);
        await this.setState({
            selectedYears: selectedList
        });

        this.props.updateYearParam(tempYearList.sort());
    };

    onYearRemove = async selectedList => {
        let tempYearList = [];
        const { selectedYears } = this.state;
        if (selectedList.length) {
            tempYearList = selectedList.map(item => item.id);
            await this.setState({
                selectedYears: selectedList
            });
            await this.props.updateYearParam(tempYearList.sort());
        } else {
            await this.setState({
                showYearSelect: false,
                selectedYears
            });
            ToastMsg("At least one year must be selected !", "warn");
            await this.setState({
                showYearSelect: true
            });
        }
    };

    isSorted = () => {
        const { tableParams = {} } = this.props;
        if (tableParams.order && !_.isEmpty(tableParams.order)) {
            return true;
        }
        return false;
    };

    exportData = async () => {
        this.setState({
            exportLoading: true
        });
        await this.props.exportTable();
        this.setState({
            exportLoading: false
        });
    };

    isWildCardActive = () => {
        const { tableParams = {} } = this.props;
        if (
            (tableParams.filters && !_.isEmpty(tableParams.filters)) ||
            (tableParams.list && !_.isEmpty(tableParams.list)) ||
            (tableParams.date_filters && !_.isEmpty(tableParams.date_filters))
        ) {
            return true;
        }
        return false;
    };

    toggleShowSendEmailModal = () => {
        const { showSendEmailModal } = this.state;
        this.setState({ showSendEmailModal: !showSendEmailModal });
    };

    renderSendEmailModal = () => {
        const { showSendEmailModal } = this.state;
        const { tableParams } = this.props;
        if (!showSendEmailModal) return null;
        return (
            <Portal
                body={<SendEmailModal entity={"calendar"} tableParams={tableParams} onCancel={() => this.setState({ showSendEmailModal: false })} />}
                onCancel={() => this.setState({ showSendEmailModal: false })}
            />
        );
    };

    render() {
        const {
            selectedLogbook,
            toggleShowIconsAndColorLegandsModal,
            handleGlobalSearch,
            globalSearchKey = null,
            showViewModal,
            resetSort,
            resetAllFilters,
            logbookById = true,
            resetWildCardFilter,
            toggleFilter,
            match: { path },
            toggleCalendarView,
            calendarView
        } = this.props;
        const { yearsList, selectedYears, showYearSelect } = this.state;
        return (
            <div className="top-fil-ara title-btn-wrapper">
                <div className="cap">{logbookById ? <h4>{selectedLogbook.name || "-"}</h4> : <h4>Edit Building Activity Properties</h4>}</div>
                <div className="right-cont">
                    {logbookById && path === "/activityCalendar" ? (
                        <div className="btn-section p-1 year-activity-cal-header">
                            {showYearSelect ? (
                                <Multiselect
                                    options={yearsList}
                                    selectedValues={selectedYears}
                                    onSelect={this.onYearSelect}
                                    onRemove={this.onYearRemove}
                                    placeholder={"Select Year"}
                                    displayValue="name"
                                    showCheckbox={true}
                                    showArrow={true}
                                    closeOnSelect={false}
                                />
                            ) : null}
                        </div>
                    ) : (
                        <>
                            {logbookById ? (
                                <div className="toggle-section">
                                    <span className="first-itm">Legacy</span>
                                    <span className="toggle-btn">
                                        <input type="checkbox" id="switch" checked={calendarView} />
                                        <label for="switch" onClick={() => toggleCalendarView()}>
                                            Toggle
                                        </label>
                                    </span>
                                    <span className="last-itm">New</span>
                                </div>
                            ) : null}
                        </>
                    )}
                    <div className="btn-section ml-1">
                        {logbookById ? (
                            <>
                                <button
                                    type="button"
                                    className={`${path !== "/activityCalendar" ? "grey-btn" : ""} green-btn`}
                                    onClick={() => history.push("/activityCalendar", { logbook: selectedLogbook })}
                                >
                                    Annual
                                </button>
                                <button
                                    type="button"
                                    className={`${path !== "/trailingCalendar" ? "grey-btn" : ""} green-btn`}
                                    onClick={() => history.push("/trailingCalendar", { logbook: selectedLogbook })}
                                >
                                    Trailing
                                </button>
                            </>
                        ) : null}
                    </div>
                    <div className="btn-section-2">
                        {logbookById && (
                            <button className="btn btn-top" title="Icon &amp; color info" onClick={() => toggleShowIconsAndColorLegandsModal()}>
                                <img alt="" src="/images/color-wheel.svg" />
                            </button>
                        )}
                        <button
                            className={`btn btn-top`}
                            title="Filter"
                            onClick={() => {
                                toggleFilter();
                            }}
                        >
                            <img src="/images/wild-card.svg" className="mr-0" alt="" />{" "}
                        </button>
                        <button
                            className={`btn btn-top text-center ${this.isWildCardActive() || globalSearchKey ? "active-reset" : ""}`}
                            title="Clear Filter"
                            onClick={() => (this.isWildCardActive() || globalSearchKey) && resetWildCardFilter()}
                        >
                            <img src="/images/reset-wild-card.svg" className="mr-0" alt="" />
                        </button>
                        <button
                            className={`btn btn-top text-center ${this.isSorted() ? "active-reset" : ""}`}
                            title="Reset Sort"
                            onClick={() => this.isSorted() && resetSort()}
                        >
                            <img src="/images/reset-icon.svg" className="mr-0" alt="" />
                        </button>
                        {this.state.exportLoading ? (
                            <button className="btn btn-top">
                                <div className="spinner-border cus-spin text-primary" role="status"></div>
                            </button>
                        ) : (
                            <button className="btn btn-top" onClick={() => this.exportData()} title="Export Excel">
                                <img src="/images/export.svg" alt="" />
                            </button>
                        )}
                        <button className="btn btn-top" title="Email" onClick={() => this.toggleShowSendEmailModal()}>
                            <img alt="" src="/images/mail.svg" />
                        </button>
                        <button className="btn btn-top" onClick={() => showViewModal()} title="Column Window">
                            <img alt="" src="/images/colmns.svg" />
                        </button>
                        <button className={`btn btn-top text-center`} onClick={() => resetAllFilters()} title="Reset All">
                            <img alt="" src="/images/reset-column.svg" />
                        </button>
                    </div>
                    <GlobalSearch handleGlobalSearch={handleGlobalSearch} globalSearchKey={globalSearchKey} />
                </div>
                {this.renderSendEmailModal()}
            </div>
        );
    }
}

export default withRouter(ActivityCalendarTableHeader);
