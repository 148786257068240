export const formatNumber = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatmoney = x => {
    return "$ " + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const thousands_separators = num => {
    let n = num.toString();
    let number = n.split(".");
    number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return number.join(".");
};

export const checkPermission = (tab, item, subitem) => {
    let user_role = localStorage.getItem("user_role");
    let user_permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (user_role !== "super_admin") {
        if (tab === "menu") {
            if (!subitem) {
                return user_permissions && user_permissions[tab] && user_permissions[tab][item] ? user_permissions[tab][item].view || false : false;
            } else {
                return (user_permissions && user_permissions[tab] && user_permissions[tab][item])[subitem]
                    ? user_permissions[tab][item][subitem].view || false
                    : false;
            }
        } else {
            return user_permissions && user_permissions[tab] && user_permissions[tab][item] ? user_permissions[tab][item][subitem] || false : false;
        }
    }
    return true;
};

export const getEntityForUrl = entity => {
    let retVal = entity;
    if (entity[entity.length - 1] === "y") {
        retVal = entity.slice(0, -1) + "ies";
    } else if (entity[entity.length - 1] === "s") {
        retVal = entity;
    } else {
        retVal = entity + "s";
    }
    if (entity === "Smart Report") {
        retVal = "dashboards";
    }
    if(entity==="Template"||entity==="User Permission"){
        retVal="permissions"
    }
    retVal = retVal.split(" ").join("_").toLowerCase();
    return retVal;
};

export const toggleTooltip = () => {
    const existingScript = document.getElementById("toggleTooltip");
    if (existingScript) {
        existingScript.remove();
    }
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.id = "toggleTooltip";
    document.body.appendChild(script);
};

export const graphDummyData = {
    eventBreakdownData: [
        {
            status: "Non-Compliant",
            count: 0
        },
        {
            status: "Incomplete",
            count: 0
        },
        {
            status: "Overdue",
            count: 0
        },
        {
            status: "In Threshold",
            count: 0
        },
        {
            status: "Completed",
            count: 0
        }
    ],
    completedEventBreakdownData: [
        {
            status: "Completed",
            count: 0
        },
        {
            status: "Completed with Failures",
            count: 0
        },
        {
            status: "Non-Compliant",
            count: 0
        }
    ],
    IncompletedEventBreakdownData: [
        {
            status: "Incomplete",
            count: 0
        },
        {
            status: "Incomplete Device",
            count: 0
        },
        {
            status: "Incomplete Document",
            count: 0
        }
    ],
    calenderAnalysisData: [
        {
            report: "Threshold Start",
            count: 0
        },
        {
            report: "Threshold Middle",
            count: 0
        },
        {
            report: "Due Next Day",
            count: 0
        },
        {
            report: "Due Today",
            count: 0
        },
        {
            report: "Threshold 3 Day End",
            count: 0
        },
        {
            report: "Threshold 1 Day End",
            count: 0
        }
    ],
    completedWithFailureData: [
        {
            activity_description: " ",
            activity_tooltip: " ",
            percentage: 0,
            count: 0
        },
        {
            activity_description: "  ",
            activity_tooltip: "  ",
            percentage: 0,
            count: 0
        },
        {
            activity_description: "   ",
            activity_tooltip: "   ",
            percentage: 0,
            count: 0
        },
        {
            activity_description: "    ",
            activity_tooltip: "    ",
            percentage: 0,
            count: 0
        },
        {
            activity_description: "     ",
            activity_tooltip: "     ",
            percentage: 0,
            count: 0
        },
        {
            activity_description: "      ",
            activity_tooltip: "      ",
            percentage: 0,
            count: 0
        }
    ]
};
