export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST"
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS"
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE"

export const ADD_REPORT_REQUEST = "ADD_REPORT_REQUEST"
export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS"
export const ADD_REPORT_FAILURE = "ADD_REPORT_FAILURE"

export const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST"
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS"
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE"

export const EDIT_REPORT_REQUEST = "EDIT_REPORT_REQUEST"
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS"
export const EDIT_REPORT_FAILURE = "EDIT_REPORT_FAILURE"

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_REPORT_BY_ID_REQUEST = "GET_REPORT_BY_ID_REQUEST"
export const GET_REPORT_BY_ID_SUCCESS = "GET_REPORT_BY_ID_SUCCESS"
export const GET_REPORT_BY_ID_FAILURE = "GET_REPORT_BY_ID_FAILURE"

export const EXPORT_REPORT_TABLE_REQUEST = "EXPORT_REPORT_TABLE_REQUEST"
export const EXPORT_REPORT_TABLE_SUCCESS = "EXPORT_REPORT_TABLE_SUCCESS"
export const EXPORT_REPORT_TABLE_FAILURE = "EXPORT_REPORT_TABLE_FAILURE"

export const GET_ALL_REPORT_LOG_REQUEST = "GET_ALL_REPORT_LOG_REQUEST";
export const GET_ALL_REPORT_LOG_SUCCESS = "GET_ALL_REPORT_LOG_SUCCESS";
export const GET_ALL_REPORT_LOG_FAILURE = "GET_ALL_REPORT_LOG_FAILURE";

export const RESTORE_REPORT_LOG_REQUEST = "RESTORE_REPORT_LOG_REQUEST";
export const RESTORE_REPORT_LOG_SUCCESS = "RESTORE_REPORT_LOG_SUCCESS";
export const RESTORE_REPORT_LOG_FAILURE = "RESTORE_REPORT_LOG_FAILURE";

export const DELETE_REPORT_LOG_REQUEST = "DELETE_REPORT_LOG_REQUEST";
export const DELETE_REPORT_LOG_SUCCESS = "DELETE_REPORT_LOG_SUCCESS";
export const DELETE_REPORT_LOG_FAILURE = "DELETE_REPORT_LOG_FAILURE";

export const UPDATE_REPORT_ENTITY_PARAMS_SUCCESS ="UPDATE_REPORT_ENTITY_PARAMS_SUCCESS";
export const UPDATE_REPORT_ENTITY_PARAMS_FAILURE ="UPDATE_REPORT_ENTITY_PARAMS_FAILURE";
