export const GET_ASSET_REQUEST = "GET_ASSET_REQUEST";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAILURE = "GET_ASSET_FAILURE";

export const ADD_ASSET_REQUEST = "ADD_ASSET_REQUEST";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_FAILURE = "ADD_ASSET_FAILURE";

export const EDIT_ASSET_BYID_REQUEST = "EDIT_ASSET_BYID_REQUEST";
export const EDIT_ASSET_BYID_SUCCESS = "EDIT_ASSET_BYID_SUCCESS";
export const EDIT_ASSET_BYID_FAILURE = "EDIT_ASSET_BYID_FAILURE";

export const DELETE_ASSET_BYID_REQUEST = "DELETE_ASSET_BYID_REQUEST";
export const DELETE_ASSET_BYID_SUCCESS = "DELETE_ASSET_BYID_SUCCESS";
export const DELETE_ASSET_BYID_FAILURE = "DELETE_ASSET_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_ASSET_BY_ID_REQUEST = "GET_ASSET_BY_ID_REQUEST"
export const GET_ASSET_BY_ID_SUCCESS = "GET_ASSET_BY_ID_SUCCESS"
export const GET_ASSET_BY_ID_FAILURE = "GET_ASSET_BY_ID_FAILURE"

export const EXPORT_ASSET_TABLE_REQUEST = "EXPORT_ASSET_TABLE_REQUEST"
export const EXPORT_ASSET_TABLE_SUCCESS = "EXPORT_ASSET_TABLE_SUCCESS"
export const EXPORT_ASSET_TABLE_FAILURE = "EXPORT_ASSET_TABLE_FAILURE"

export const GET_ALL_ASSET_LOG_REQUEST = "GET_ALL_ASSET_LOG_REQUEST";
export const GET_ALL_ASSET_LOG_SUCCESS = "GET_ALL_ASSET_LOG_SUCCESS";
export const GET_ALL_ASSET_LOG_FAILURE = "GET_ALL_ASSET_LOG_FAILURE";

export const RESTORE_ASSET_LOG_REQUEST = "RESTORE_ASSET_LOG_REQUEST";
export const RESTORE_ASSET_LOG_SUCCESS = "RESTORE_ASSET_LOG_SUCCESS";
export const RESTORE_ASSET_LOG_FAILURE = "RESTORE_ASSET_LOG_FAILURE";

export const DELETE_ASSET_LOG_REQUEST = "DELETE_ASSET_LOG_REQUEST";
export const DELETE_ASSET_LOG_SUCCESS = "DELETE_ASSET_LOG_SUCCESS";
export const DELETE_ASSET_LOG_FAILURE = "DELETE_ASSET_LOG_FAILURE";

export const UPDATE_ASSET_ENTITY_PARAMS_SUCCESS ="UPDATE_ASSET_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ASSET_ENTITY_PARAMS_FAILURE ="UPDATE_ASSET_ENTITY_PARAMS_FAILURE";

export const GET_ALL_ASSET_IMAGE_REQUEST = "GET_ALL_ASSET_IMAGE_REQUEST"
export const GET_ALL_ASSET_IMAGE_SUCCESS = "GET_ALL_ASSET_IMAGE_SUCCESS"
export const GET_ALL_ASSET_IMAGE_FAILURE = "GET_ALL_ASSET_IMAGE_FAILURE"

export const UPLOAD_ASSET_IMAGE_REQUEST = "UPLOAD_ASSET_IMAGE_REQUEST"
export const UPLOAD_ASSET_IMAGE_SUCCESS = "UPLOAD_ASSET_IMAGE_SUCCESS"
export const UPLOAD_ASSET_IMAGE_FAILURE = "UPLOAD_ASSET_IMAGE_FAILURE"

export const UPDATE_ASSET_IMAGE_REQUEST = "UPDATE_ASSET_IMAGE_REQUEST"
export const UPDATE_ASSET_IMAGE_SUCCESS = "UPDATE_ASSET_IMAGE_SUCCESS"
export const UPDATE_ASSET_IMAGE_FAILURE = "UPDATE_ASSET_IMAGE_FAILURE"

export const DELETE_ASSET_IMAGE_REQUEST = "DELETE_ASSET_IMAGE_REQUEST"
export const DELETE_ASSET_IMAGE_SUCCESS = "DELETE_ASSET_IMAGE_SUCCESS"
export const DELETE_ASSET_IMAGE_FAILURE = "DELETE_ASSET_IMAGE_FAILURE"

