export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const ADD_NOTIFICATION_REQUEST = "ADD_NOTIFICATION_REQUEST";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION_FAILURE = "ADD_NOTIFICATION_FAILURE";

export const GET_NOTIFICATION_BYID_REQUEST = "GET_NOTIFICATION_BYID_REQUEST";
export const GET_NOTIFICATION_BYID_SUCCESS = "GET_NOTIFICATION_BYID_SUCCESS";
export const GET_NOTIFICATION_BYID_FAILURE = "GET_NOTIFICATION_BYID_FAILURE";

export const EDIT_NOTIFICATION_BYID_REQUEST = "EDIT_NOTIFICATION_BYID_REQUEST";
export const EDIT_NOTIFICATION_BYID_SUCCESS = "EDIT_NOTIFICATION_BYID_SUCCESS";
export const EDIT_NOTIFICATION_BYID_FAILURE = "EDIT_NOTIFICATION_BYID_FAILURE";

export const DELETE_NOTIFICATION_BYID_REQUEST = "DELETE_NOTIFICATION_BYID_REQUEST";
export const DELETE_NOTIFICATION_BYID_SUCCESS = "DELETE_NOTIFICATION_BYID_SUCCESS";
export const DELETE_NOTIFICATION_BYID_FAILURE = "DELETE_NOTIFICATION_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_NOTIFICATION_BY_ID_REQUEST = "GET_NOTIFICATION_BY_ID_REQUEST"
export const GET_NOTIFICATION_BY_ID_SUCCESS = "GET_NOTIFICATION_BY_ID_SUCCESS"
export const GET_NOTIFICATION_BY_ID_FAILURE = "GET_NOTIFICATION_BY_ID_FAILURE"

export const EXPORT_NOTIFICATION_TABLE_REQUEST = "EXPORT_NOTIFICATION_TABLE_REQUEST"
export const EXPORT_NOTIFICATION_TABLE_SUCCESS = "EXPORT_NOTIFICATION_TABLE_SUCCESS"
export const EXPORT_NOTIFICATION_TABLE_FAILURE = "EXPORT_NOTIFICATION_TABLE_FAILURE"

export const GET_ALL_NOTIFICATION_LOG_REQUEST = "GET_ALL_NOTIFICATION_LOG_REQUEST";
export const GET_ALL_NOTIFICATION_LOG_SUCCESS = "GET_ALL_NOTIFICATION_LOG_SUCCESS";
export const GET_ALL_NOTIFICATION_LOG_FAILURE = "GET_ALL_NOTIFICATION_LOG_FAILURE";

export const RESTORE_NOTIFICATION_LOG_REQUEST = "RESTORE_NOTIFICATION_LOG_REQUEST";
export const RESTORE_NOTIFICATION_LOG_SUCCESS = "RESTORE_NOTIFICATION_LOG_SUCCESS";
export const RESTORE_NOTIFICATION_LOG_FAILURE = "RESTORE_NOTIFICATION_LOG_FAILURE";

export const DELETE_NOTIFICATION_LOG_REQUEST = "DELETE_NOTIFICATION_LOG_REQUEST";
export const DELETE_NOTIFICATION_LOG_SUCCESS = "DELETE_NOTIFICATION_LOG_SUCCESS";
export const DELETE_NOTIFICATION_LOG_FAILURE = "DELETE_NOTIFICATION_LOG_FAILURE";

export const UPDATE_NOTIFICATION_ENTITY_PARAMS_SUCCESS ="UPDATE_NOTIFICATION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_NOTIFICATION_ENTITY_PARAMS_FAILURE ="UPDATE_NOTIFICATION_ENTITY_PARAMS_FAILURE";

