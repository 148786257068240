export const GET_USER_EMAIL_LIST_REQUEST = "GET_USER_EMAIL_LIST_REQUEST";
export const GET_USER_EMAIL_LIST_SUCCESS = "GET_USER_EMAIL_LIST_SUCCESS";
export const GET_USER_EMAIL_LIST_FAILURE = "GET_USER_EMAIL_LIST_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const SEND_MAIL_WITH_ATTACHMENT_REQUEST = "SEND_MAIL_WITH_ATTACHMENT_REQUEST";
export const SEND_MAIL_WITH_ATTACHMENT_SUCCESS = "SEND_MAIL_WITH_ATTACHMENT_SUCCESS";
export const SEND_MAIL_WITH_ATTACHMENT_FAILURE = "SEND_MAIL_WITH_ATTACHMENT_FAILURE";

export const GET_INBOX_MAILS_REQUEST = "GET_INBOX_MAILS_REQUEST";
export const GET_INBOX_MAILS_SUCCESS = "GET_INBOX_MAILS_SUCCESS";
export const GET_INBOX_MAILS_FAILURE = "GET_INBOX_MAILS_FAILURE";

export const GET_SENT_MAILS_REQUEST = "GET_SENT_MAILS_REQUEST";
export const GET_SENT_MAILS_SUCCESS = "GET_SENT_MAILS_SUCCESS";
export const GET_SENT_MAILS_FAILURE = "GET_SENT_MAILS_FAILURE";

export const GET_EMAIL_DETAILS_REQUEST = "GET_EMAIL_DETAILS_REQUEST";
export const GET_EMAIL_DETAILS_SUCCESS = "GET_EMAIL_DETAILS_SUCCESS";
export const GET_EMAIL_DETAILS_FAILURE = "GET_EMAIL_DETAILS_FAILURE";

export const GET_ALL_SENT_MAILS_REQUEST = "GET_ALL_SENT_MAILS_REQUEST";
export const GET_ALL_SENT_MAILS_SUCCESS = "GET_ALL_SENT_MAILS_SUCCESS";
export const GET_ALL_SENT_MAILS_FAILURE = "GET_ALL_SENT_MAILS_FAILURE";
