export const GET_ACTIVITY_CLIENT_REQUEST = "GET_ACTIVITY_CLIENT_REQUEST";
export const GET_ACTIVITY_CLIENT_SUCCESS = "GET_ACTIVITY_CLIENT_SUCCESS";
export const GET_ACTIVITY_CLIENT_FAILURE = "GET_ACTIVITY_CLIENT_FAILURE";

export const ADD_ACTIVITY_CLIENT_REQUEST = "ADD_ACTIVITY_CLIENT_REQUEST";
export const ADD_ACTIVITY_CLIENT_SUCCESS = "ADD_ACTIVITY_CLIENT_SUCCESS";
export const ADD_ACTIVITY_CLIENT_FAILURE = "ADD_ACTIVITY_CLIENT_FAILURE";

export const EDIT_ACTIVITY_CLIENT_BYID_REQUEST = "EDIT_ACTIVITY_CLIENT_BYID_REQUEST";
export const EDIT_ACTIVITY_CLIENT_BYID_SUCCESS = "EDIT_ACTIVITY_CLIENT_BYID_SUCCESS";
export const EDIT_ACTIVITY_CLIENT_BYID_FAILURE = "EDIT_ACTIVITY_CLIENT_BYID_FAILURE";

export const DELETE_ACTIVITY_CLIENT_BYID_REQUEST = "DELETE_ACTIVITY_CLIENT_BYID_REQUEST";
export const DELETE_ACTIVITY_CLIENT_BYID_SUCCESS = "DELETE_ACTIVITY_CLIENT_BYID_SUCCESS";
export const DELETE_ACTIVITY_CLIENT_BYID_FAILURE = "DELETE_ACTIVITY_CLIENT_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_ACTIVITY_CLIENT_BY_ID_REQUEST = "GET_ACTIVITY_CLIENT_BY_ID_REQUEST"
export const GET_ACTIVITY_CLIENT_BY_ID_SUCCESS = "GET_ACTIVITY_CLIENT_BY_ID_SUCCESS"
export const GET_ACTIVITY_CLIENT_BY_ID_FAILURE = "GET_ACTIVITY_CLIENT_BY_ID_FAILURE"

export const EXPORT_ACTIVITY_CLIENT_TABLE_REQUEST = "EXPORT_ACTIVITY_CLIENT_TABLE_REQUEST"
export const EXPORT_ACTIVITY_CLIENT_TABLE_SUCCESS = "EXPORT_ACTIVITY_CLIENT_TABLE_SUCCESS"
export const EXPORT_ACTIVITY_CLIENT_TABLE_FAILURE = "EXPORT_ACTIVITY_CLIENTT_TABLE_FAILURE"

export const GET_ALL_CLIENT_LOG_REQUEST = "GET_ALL_CLIENT_LOG_REQUEST";
export const GET_ALL_CLIENT_LOG_SUCCESS = "GET_ALL_CLIENT_LOG_SUCCESS";
export const GET_ALL_CLIENT_LOG_FAILURE = "GET_ALL_CLIENT_LOG_FAILURE";

export const RESTORE_CLIENT_LOG_REQUEST = "RESTORE_CLIENT_LOG_REQUEST";
export const RESTORE_CLIENT_LOG_SUCCESS = "RESTORE_CLIENT_LOG_SUCCESS";
export const RESTORE_CLIENT_LOG_FAILURE = "RESTORE_CLIENT_LOG_FAILURE";

export const DELETE_CLIENT_LOG_REQUEST = "DELETE_CLIENT_LOG_REQUEST";
export const DELETE_CLIENT_LOG_SUCCESS = "DELETE_CLIENT_LOG_SUCCESS";
export const DELETE_CLIENT_LOG_FAILURE = "DELETE_CLIENT_LOG_FAILURE";

export const UPDATE_CLIENT_ENTITY_PARAMS_SUCCESS ="UPDATE_CLIENT_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CLIENT_ENTITY_PARAMS_FAILURE ="UPDATE_CLIENT_ENTITY_PARAMS_FAILURE";
