export const GET_LOGBOOK_REQUEST = "GET_LOGBOOK_REQUEST"
export const GET_LOGBOOK_SUCCESS = "GET_LOGBOOK_SUCCESS"
export const GET_LOGBOOK_FAILURE = "GET_LOGBOOK_FAILURE"

export const ADD_LOGBOOK_REQUEST = "ADD_LOGBOOK_REQUEST"
export const ADD_LOGBOOK_SUCCESS = "ADD_LOGBOOK_SUCCESS"
export const ADD_LOGBOOK_FAILURE = "ADD_LOGBOOK_FAILURE"

export const DELETE_LOGBOOK_REQUEST = "DELETE_LOGBOOK_REQUEST"
export const DELETE_LOGBOOK_SUCCESS = "DELETE_LOGBOOK_SUCCESS"
export const DELETE_LOGBOOK_FAILURE = "DELETE_LOGBOOK_FAILURE"

export const EDIT_LOGBOOK_REQUEST = "EDIT_LOGBOOK_REQUEST"
export const EDIT_LOGBOOK_SUCCESS = "EDIT_LOGBOOK_SUCCESS"
export const EDIT_LOGBOOK_FAILURE = "EDIT_LOGBOOK_FAILURE"

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_LOGBOOK_BY_ID_REQUEST = "GET_LOGBOOK_BY_ID_REQUEST"
export const GET_LOGBOOK_BY_ID_SUCCESS = "GET_LOGBOOK_BY_ID_SUCCESS"
export const GET_LOGBOOK_BY_ID_FAILURE = "GET_LOGBOOK_BY_ID_FAILURE"

export const EXPORT_LOGBOOK_TABLE_REQUEST = "EXPORT_LOGBOOK_TABLE_REQUEST"
export const EXPORT_LOGBOOK_TABLE_SUCCESS = "EXPORT_LOGBOOK_TABLE_SUCCESS"
export const EXPORT_LOGBOOK_TABLE_FAILURE = "EXPORT_LOGBOOK_TABLE_FAILURE"

export const GET_ALL_LOGBOOK_LOG_REQUEST = "GET_ALL_LOGBOOK_LOG_REQUEST";
export const GET_ALL_LOGBOOK_LOG_SUCCESS = "GET_ALL_LOGBOOK_LOG_SUCCESS";
export const GET_ALL_LOGBOOK_LOG_FAILURE = "GET_ALL_LOGBOOK_LOG_FAILURE";

export const RESTORE_LOGBOOK_LOG_REQUEST = "RESTORE_LOGBOOK_LOG_REQUEST";
export const RESTORE_LOGBOOK_LOG_SUCCESS = "RESTORE_LOGBOOK_LOG_SUCCESS";
export const RESTORE_LOGBOOK_LOG_FAILURE = "RESTORE_LOGBOOK_LOG_FAILURE";

export const DELETE_LOGBOOK_LOG_REQUEST = "DELETE_LOGBOOK_LOG_REQUEST";
export const DELETE_LOGBOOK_LOG_SUCCESS = "DELETE_LOGBOOK_LOG_SUCCESS";
export const DELETE_LOGBOOK_LOG_FAILURE = "DELETE_LOGBOOK_LOG_FAILURE";

export const UPDATE_LOGBOOK_ENTITY_PARAMS_SUCCESS ="UPDATE_LOGBOOK_ENTITY_PARAMS_SUCCESS";
export const UPDATE_LOGBOOK_ENTITY_PARAMS_FAILURE ="UPDATE_LOGBOOK_ENTITY_PARAMS_FAILURE";

export const GET_ALL_LOGBOOK_IMAGE_REQUEST = "GET_ALL_LOGBOOK_IMAGE_REQUEST"
export const GET_ALL_LOGBOOK_IMAGE_SUCCESS = "GET_ALL_LOGBOOK_IMAGE_SUCCESS"
export const GET_ALL_LOGBOOK_IMAGE_FAILURE = "GET_ALL_LOGBOOK_IMAGE_FAILURE"

export const UPLOAD_LOGBOOK_IMAGE_REQUEST = "UPLOAD_LOGBOOK_IMAGE_REQUEST"
export const UPLOAD_LOGBOOK_IMAGE_SUCCESS = "UPLOAD_LOGBOOK_IMAGE_SUCCESS"
export const UPLOAD_LOGBOOK_IMAGE_FAILURE = "UPLOAD_LOGBOOK_IMAGE_FAILURE"

export const UPDATE_LOGBOOK_IMAGE_REQUEST = "UPDATE_LOGBOOK_IMAGE_REQUEST"
export const UPDATE_LOGBOOK_IMAGE_SUCCESS = "UPDATE_LOGBOOK_IMAGE_SUCCESS"
export const UPDATE_LOGBOOK_IMAGE_FAILURE = "UPDATE_LOGBOOK_IMAGE_FAILURE"

export const DELETE_LOGBOOK_IMAGE_REQUEST = "DELETE_LOGBOOK_IMAGE_REQUEST"
export const DELETE_LOGBOOK_IMAGE_SUCCESS = "DELETE_LOGBOOK_IMAGE_SUCCESS"
export const DELETE_LOGBOOK_IMAGE_FAILURE = "DELETE_LOGBOOK_IMAGE_FAILURE"
