export const GET_DEEMING_AGENCY_FREQUENCY_REQUEST = "GET_DEEMING_AGENCY_FREQUENCY_REQUEST"
export const GET_DEEMING_AGENCY_FREQUENCY_SUCCESS = "GET_DEEMING_AGENCY_FREQUENCY_SUCCESS"
export const GET_DEEMING_AGENCY_FREQUENCY_FAILURE = "GET_DEEMING_AGENCY_FREQUENCY_FAILURE"

export const ADD_DEEMING_AGENCY_FREQUENCY_REQUEST = "ADD_DEEMING_AGENCY_FREQUENCY_REQUEST"
export const ADD_DEEMING_AGENCY_FREQUENCY_SUCCESS = "ADD_DEEMING_AGENCY_FREQUENCY_SUCCESS"
export const ADD_DEEMING_AGENCY_FREQUENCY_FAILURE = "ADD_DEEMING_AGENCY_FREQUENCY_FAILURE"

export const DELETE_DEEMING_AGENCY_FREQUENCY_REQUEST = "DELETE_DEEMING_AGENCY_FREQUENCY_REQUEST"
export const DELETE_DEEMING_AGENCY_FREQUENCY_SUCCESS = "DELETE_DEEMING_AGENCY_FREQUENCY_SUCCESS"
export const DELETE_DEEMING_AGENCY_FREQUENCY_FAILURE = "DELETE_DEEMING_AGENCY_FREQUENCY_FAILURE"

export const EDIT_DEEMING_AGENCY_FREQUENCY_REQUEST = "EDIT_DEEMING_AGENCY_FREQUENCY_REQUEST"
export const EDIT_DEEMING_AGENCY_FREQUENCY_SUCCESS = "EDIT_DEEMING_AGENCY_FREQUENCY_SUCCESS"
export const EDIT_DEEMING_AGENCY_FREQUENCY_FAILURE = "EDIT_DEEMING_AGENCY_FREQUENCY_FAILURE"

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_DEEMING_AGENCY_FREQUENCY_BY_ID_REQUEST = "GET_DEEMING_AGENCY_FREQUENCY_BY_ID_REQUEST"
export const GET_DEEMING_AGENCY_FREQUENCY_BY_ID_SUCCESS = "GET_DEEMING_AGENCY_FREQUENCY_BY_ID_SUCCESS"
export const GET_DEEMING_AGENCY_FREQUENCY_BY_ID_FAILURE = "GET_DEEMING_AGENCY_FREQUENCY_BY_ID_FAILURE"

export const EXPORT_DEEMING_AGENCY_FREQUENCY_TABLE_REQUEST = "EXPORT_DEEMING_AGENCY_FREQUENCY_TABLE_REQUEST"
export const EXPORT_DEEMING_AGENCY_FREQUENCY_TABLE_SUCCESS = "EXPORT_DEEMING_AGENCY_FREQUENCY_TABLE_SUCCESS"
export const EXPORT_DEEMING_AGENCY_FREQUENCY_TABLE_FAILURE = "EXPORT_DEEMING_AGENCY_FREQUENCY_TABLE_FAILURE"

export const GET_ALL_DEEMING_AGENCY_FREQUENCY_LOG_REQUEST = "GET_ALL_DEEMING_AGENCY_FREQUENCY_LOG_REQUEST";
export const GET_ALL_DEEMING_AGENCY_FREQUENCY_LOG_SUCCESS = "GET_ALL_DEEMING_AGENCY_FREQUENCY_LOG_SUCCESS";
export const GET_ALL_DEEMING_AGENCY_FREQUENCY_LOG_FAILURE = "GET_ALL_DEEMING_AGENCY_FREQUENCY_LOG_FAILURE";

export const RESTORE_DEEMING_AGENCY_FREQUENCY_LOG_REQUEST = "RESTORE_DEEMING_AGENCY_FREQUENCY_LOG_REQUEST";
export const RESTORE_DEEMING_AGENCY_FREQUENCY_LOG_SUCCESS = "RESTORE_DEEMING_AGENCY_FREQUENCY_LOG_SUCCESS";
export const RESTORE_DEEMING_AGENCY_FREQUENCY_LOG_FAILURE = "RESTORE_DEEMING_AGENCY_FREQUENCY_LOG_FAILURE";

export const DELETE_DEEMING_AGENCY_FREQUENCY_LOG_REQUEST = "DELETE_DEEMING_AGENCY_FREQUENCY_LOG_REQUEST";
export const DELETE_DEEMING_AGENCY_FREQUENCY_LOG_SUCCESS = "DELETE_DEEMING_AGENCY_FREQUENCY_LOG_SUCCESS";
export const DELETE_DEEMING_AGENCY_FREQUENCY_LOG_FAILURE = "DELETE_DEEMING_AGENCY_FREQUENCY_LOG_FAILURE";

export const UPDATE_DEEMING_AGENCY_FREQUENCY_ENTITY_PARAMS_SUCCESS ="UPDATE_DEEMING_AGENCY_FREQUENCY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_DEEMING_AGENCY_FREQUENCY_ENTITY_PARAMS_FAILURE ="UPDATE_DEEMING_AGENCY_FREQUENCY_ENTITY_PARAMS_FAILURE";
