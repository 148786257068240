export const GET_FREQUENCY_REQUEST = "GET_FREQUENCY_REQUEST";
export const GET_FREQUENCY_SUCCESS = "GET_FREQUENCY_SUCCESS";
export const GET_FREQUENCY_FAILURE = "GET_FREQUENCY_FAILURE";

export const ADD_FREQUENCY_REQUEST = "ADD_FREQUENCY_REQUEST";
export const ADD_FREQUENCY_SUCCESS = "ADD_FREQUENCY_SUCCESS";
export const ADD_FREQUENCY_FAILURE = "ADD_FREQUENCY_FAILURE";

export const GET_FREQUENCY_BYID_REQUEST = "GET_FREQUENCY_BYID_REQUEST";
export const GET_FREQUENCY_BYID_SUCCESS = "GET_FREQUENCY_BYID_SUCCESS";
export const GET_FREQUENCY_BYID_FAILURE = "GET_FREQUENCY_BYID_FAILURE";

export const EDIT_FREQUENCY_BYID_REQUEST = "EDIT_FREQUENCY_BYID_REQUEST";
export const EDIT_FREQUENCY_BYID_SUCCESS = "EDIT_FREQUENCY_BYID_SUCCESS";
export const EDIT_FREQUENCY_BYID_FAILURE = "EDIT_FREQUENCY_BYID_FAILURE";

export const DELETE_FREQUENCY_BYID_REQUEST = "DELETE_FREQUENCY_BYID_REQUEST";
export const DELETE_FREQUENCY_BYID_SUCCESS = "DELETE_FREQUENCY_BYID_SUCCESS";
export const DELETE_FREQUENCY_BYID_FAILURE = "DELETE_FREQUENCY_BYID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_FREQUENCY_BY_ID_REQUEST = "GET_FREQUENCY_BY_ID_REQUEST"
export const GET_FREQUENCY_BY_ID_SUCCESS = "GET_FREQUENCY_BY_ID_SUCCESS"
export const GET_FREQUENCY_BY_ID_FAILURE = "GET_FREQUENCY_BY_ID_FAILURE"

export const EXPORT_FREQUENCY_TABLE_REQUEST = "EXPORT_FREQUENCY_TABLE_REQUEST"
export const EXPORT_FREQUENCY_TABLE_SUCCESS = "EXPORT_FREQUENCY_TABLE_SUCCESS"
export const EXPORT_FREQUENCY_TABLE_FAILURE = "EXPORT_FREQUENCY_TABLE_FAILURE"

export const GET_ALL_FREQUENCY_LOG_REQUEST = "GET_ALL_FREQUENCY_LOG_REQUEST";
export const GET_ALL_FREQUENCY_LOG_SUCCESS = "GET_ALL_FREQUENCY_LOG_SUCCESS";
export const GET_ALL_FREQUENCY_LOG_FAILURE = "GET_ALL_FREQUENCY_LOG_FAILURE";

export const RESTORE_FREQUENCY_LOG_REQUEST = "RESTORE_FREQUENCY_LOG_REQUEST";
export const RESTORE_FREQUENCY_LOG_SUCCESS = "RESTORE_FREQUENCY_LOG_SUCCESS";
export const RESTORE_FREQUENCY_LOG_FAILURE = "RESTORE_FREQUENCY_LOG_FAILURE";

export const DELETE_FREQUENCY_LOG_REQUEST = "DELETE_FREQUENCY_LOG_REQUEST";
export const DELETE_FREQUENCY_LOG_SUCCESS = "DELETE_FREQUENCY_LOG_SUCCESS";
export const DELETE_FREQUENCY_LOG_FAILURE = "DELETE_FREQUENCY_LOG_FAILURE";

export const UPDATE_FREQUENCY_ENTITY_PARAMS_SUCCESS ="UPDATE_FREQUENCY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_FREQUENCY_ENTITY_PARAMS_FAILURE ="UPDATE_FREQUENCY_ENTITY_PARAMS_FAILURE";

